:root {
    --theme: 'light';
    --text: rgba(175,175,175,1);
    --divider: rgba(150,150,150,0.15);
    --soft_border: rgba(175,175,175,1);
    --textfield: rgba(245,245,245,1);
    --primary: rgba(245,245,245,1);
    --grey: rgba(175,175,175,1);
    --sat: env(safe-area-inset-top, 0px);
    --sar: env(safe-area-inset-right, 0px);
    --sab: env(safe-area-inset-bottom, 0px);
    --sal: env(safe-area-inset-left, 0px);
    --PhoneInput-color--focus: #4A90E2 !important;
}

/* Nue */
body {
    transition: background-color 1000ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
body.light {
    background-color: #F0F1F5 !important;
}
body.dark {
    background-color: rgba(25,25,25,1) !important;
}

.disable-text-highlight {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.root-container {
    transition: background-color 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

/* full calendar */
.fc-media-screen {
  max-height: 900px !important;
}
.fc-h-event {
  background-color: transparent !important;
  border: none !important;
}

.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}
.disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}
.sortable-container {
    z-index: 7500
}
.flexible-table-column {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 0px
}
.mapboxgl-canvas {
    overflow: hidden !important;
}
.pinstripes {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255,255,255,0.25),
        rgba(255,255,255,0.25) 10px,
        rgba(255,255,255,0.1) 10px,
        rgba(255,255,255,0.1) 20px
    );
}
.pinstripes.dark {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255,255,255,0.12),
        rgba(255,255,255,0.12) 10px,
        rgba(255,255,255,0.075) 10px,
        rgba(255,255,255,0.075) 20px
    );
}

.pinstripes.in_demo {
    background: repeating-linear-gradient(
      -45deg,
      rgba(75,144,226,0.15),
      rgba(75,144,226,0.15) 10px,
      rgba(75,144,226,0.05) 10px,
      rgba(75,144,226,0.05) 20px
    );
    border-radius: 8px
}

/* Scheduler resize */
.cursor-ewresize {
    opacity: 0.65 !important;
    cursor: ew-resize !important;
}
.cursor-ewresize:hover {
    opacity: 0.65 !important;
    cursor: ew-resize !important;
}

/* Tag input field */
.react-tag-input {
    border-radius: 8px !important;
    border: 1px solid rgba(175,175,175,0.25) !important;
    background-color: var(--textfield) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.react-tag-input:focus-within {
    box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important
}
.react-tag-input__input {
    font-weight: 500;
    color: var(--text) !important;
    font-size: 12px !important;
}
@media (prefers-color-scheme: dark) {
    .react-tag-input:focus-within {
        box-shadow: 4px 4px 7px rgba(0,0,0,0.25), -4px -4px 7px rgba(255,255,255,0.025) !important
    }
}

/* wysiwyg editor */
.rt-editor-toolbar {
    align-items: center !important;
    background-color: rgba(0,0,0,0) !important;
    border-color: var(--soft_border) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    justify-content: center !important;
    margin-bottom: 0px !important;
    padding: 4px !important;
}
.rt-editor-toolbar div {
  margin-bottom: 0px !important;
}
.rdw-editor-toolbar-hidden {
  display: none !important;
}
.rdw-option-wrapper {
    background-color: rgba(0,0,0,0) !important;
    border: none !important;
    box-shadow: none !important;
    color: var(--text) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    height: 25px !important;
    margin: 0px !important;
    min-width: 25px !important;
    padding: 8px !important;
}
.rdw-option-wrapper img {
  height: 12px !important;
  width: 12px !important;
}
.rdw-option-wrapper:hover {
    background-color: rgba(150,150,150,0.15) !important;
    box-shadow: none !important;
}
.rdw-colorpicker-option {
  padding: 2px !important
}
.rdw-option-active {
    background-color: rgba(150,150,150,0.15) !important;
    box-shadow: none !important;
}
.rdw-dropdown-wrapper  {
    height: 30px !important;
    padding-left: 8px !important;
    border-radius: 8px !important;
    border-color: var(--soft_border) !important;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.rdw-dropdown-wrapper:hover  {
    box-shadow: 0px 2px 8px rgba(0,0,0,0.1) !important
}
.rdw-dropdown-selectedtext {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--text) !important;
}
.rdw-dropdown-selectedtext:hover {
    text-decoration: none !important;
}
.rdw-dropdown-optionwrapper {
    margin-top: 6px !important
}
.rdw-colorpicker-option {
    border-radius: 17.5px !important;
    box-shadow: none !important;
    height: 30px !important;
    margin: 2px !important;
    padding: 0px !important;
    width: 30px !important;
}
.rdw-colorpicker-option:hover {
    box-shadow: none !important;
}
.rdw-colorpicker-cube {
    width: 30px !important;
    height: 30px !important;
    border-radius: 17.5px !important;
}
.rdw-colorpicker-cube:hover {
    opacity: 0.75 !important
}

.rdw-colorpicker-modal {
    border: 1px solid var(--soft_border) !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    margin: 0 !important;
    width: 168px !important;
}
.rdw-colorpicker-modal-header {
  display: none !important;
}
.rdw-colorpicker-modal-style-label {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: var(--text) !important;
}
.rdw-colorpicker-modal-style-label-active {
  border-bottom: none !important;
  color: #4A90E2 !important;
}
.rdw-emoji-modal {
    border: 1px solid var(--soft_border) !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    justify-content: center !important;
    margin: 0 !important;
    width: 215px !important;
}
.rdw-emoji-icon {
  margin: 6px !important
}
.rdw-dropdown-optionwrapper {
    padding: 8px !important;
    margin-top: 6px !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    border: 1px solid var(--soft_border) !important;
}
.rdw-dropdown-selectedtext {
    width: 50px !important
}
.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose {
    margin-right: 4px
}
.rdw-dropdownoption-default.rdw-fontsize-option {
    border-radius: 6px !important
}
.rt-editor-container {
    font-size: 12px;
    font-weight: 600;
    overflow-y: scroll !important;
    padding: 0px 14px 0px 14px !important;
}
.rt-editor-container.light {
    background-color: rgb(252,252,252) !important;
}
.rt-editor-container.dark {
    background-color: rgba(150,150,150,0.15) !important;
}
.rt-editor-container.read_only {
  border: none !important;
  border-radius: none !important;
}
.rt-editor-container.editable {
    border-bottom: 1px solid var(--soft_border) !important;
    border-left: 1px solid var(--soft_border) !important;
    border-right: 1px solid var(--soft_border) !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    max-height: calc(70vh) !important;
    min-height: 125px !important;
}
.rt-button.light {
    background-color: rgb(252,252,252) !important;
}
.rt-button.dark {
    background-color: rgba(75,75,75,1) !important;
}
.rt-modal {
  border-radius: 15px !important;
  height: auto !important;
  max-height: 400px !important;
  overflow-y: scroll;
}
.rt-modal.light {
    background-color: rgb(252,252,252) !important;
}
.rt-modal.dark {
    background-color: rgba(75,75,75,1) !important;
}
.rdw-colorpicker-modal-options {
    margin: 0px !important;
    overflow: hidden !important;
}
@media (prefers-color-scheme: dark) {
    .rdw-option-wrapper {
        background-color: rgba(75,75,75,1) !important;
    }
    .rdw-dropdown-carettoopen {
        border-top-color: white !important;
    }
    .rdw-dropdown-carettoclose {
        border-bottom-color: white !important;
    }
}
@media (max-width:480px)  {
  .rt-editor-container {
    font-size: 16px;
  }
  .rdw-colorpicker-cube {
    min-height: 25px !important;
    min-width: 30px !important;
  }
  .rdw-editor-toolbar {
    padding: 12px !important;
  }
  .rdw-emoji-icon {
    height: 30px !important;
    font-size: 32px !important;
    width: 30px !important;
  }
  .rdw-option-wrapper {
    border: 1px solid var(--grey) !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    height: 35px !important;
    margin-left: 4px !important; 
    margin-right: 4px !important; 
    margin-top: 8px !important; 
    min-width: 30px !important;
    padding: 8px !important;
  }
  .rdw-option-wrapper.rdw-colorpicker-option {
    border: none !important;
    height: 35px !important;
    min-width: 35px !important;
  }
  .rdw-option-wrapper img {
    height: 20px !important;
    width: 20px !important;
  }
  .rt-modal {
    max-height: 500px !important;
  }
  .rt-modal::-webkit-scrollbar {
    width: 0px !important;
  }
  .rt-modal::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0) !important;
  }
  .rdw-colorpicker-modal {
    max-height: 500px !important;
  }
}

/* Phone number input field */
.PhoneInputCountrySelectArrow {
    margin-bottom: 2px !important;
    margin-left: 6px !important;
    width: 5px !important;
    height: 5px !important;
}
.PhoneInputCountryIcon {
    border-width: 0px !important;
    width: 20px !important;
    height: 13px !important;
    object-fit: cover !important;
    overflow: hidden !important;
    border-radius: 2px !important;
}
.PhoneInputCountryIcon--border {
    background-color: rgba(0,0,0,0) !important;
    box-shadow: none !important;
}
.PhoneInputInput {
    background: rgba(0,0,0,0);
    border-width: 0px;
    padding: 0.5275rem 0.75rem;
    color: var(--text) !important;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    will-change: border-color, box-shadow;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.PhoneInputInput:active {
    background: rgba(0,0,0,0) !important;
    border-width: 0px !important;
    border: none !important;
    outline: none !important;
}
.PhoneInputInput:focus {
    background: rgba(0,0,0,0) !important;
    border-width: 0px !important;
    border: none !important;
    outline: none !important;
}
.PhoneInputInput.light:-webkit-autofill, .PhoneInputInput.light:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;
  border-top: 1px solid rgba(175,175,175,0.25) !important;
  border-bottom: 1px solid rgba(175,175,175,0.25) !important;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  -webkit-text-fill-color: #4A4A4A;
}
.PhoneInputInput.light:-webkit-autofill, .PhoneInputInput.light:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px rgba(245,245,245,1) inset;
  border-top: 1px solid rgba(175,175,175,0.25) !important;
  border-bottom: 1px solid rgba(175,175,175,0.25) !important;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  -webkit-text-fill-color: #4A4A4A;
}
.PhoneInputInput.dark:-webkit-autofill, .PhoneInputInput.dark:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px rgba(100,100,100,1) inset;
  border-top: 1px solid rgba(125,125,125,1) !important;
  border-bottom: 1px solid rgba(125,125,125,1) !important;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  -webkit-text-fill-color: white;
}
.PhoneInputInput.dark:-webkit-autofill, .PhoneInputInput.dark:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px rgba(85,85,85,1) inset;
  border-top: 1px solid rgba(110,110,110,1) !important;
  border-bottom: 1px solid rgba(110,110,110,1) !important;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  -webkit-text-fill-color: white;
}

/* Patterns */
.anchors {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%23bababa' fill-opacity='0.2' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E");
}
.bubbles {
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.charlie_brown {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23bababa' fill-opacity='0.2'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.circles {
    background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23bababa' fill-opacity='0.2'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.diamonds {
    background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.dots {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}
.leaves {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23bababa' fill-opacity='0.2' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}
.stars {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%23bababa' fill-opacity='0.2'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
}
.stripes {
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.triangles {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23bababa' fill-opacity='0.2'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* Loaders */
.circle-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -28px 0 0 -28px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}
.circle-loader:after {
  content: '';
  background: trasparent;
  width: 110%;
  height: 110%;
  position: absolute;
  border-radius: 100%;
  top: -15%;
  left: -15%;
  opacity: 1;
  box-shadow: rgba(255, 255, 255, 0.75) -4px -5px 3px -3px;
  animation: rotate 1.5s infinite linear;
}

.rotate {
    animation: rotate 2.5s infinite linear;
}
.rotate-once {
  animation: rotate 1.5s ease-out;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

/* View entry */
.view-entry.light:hover {
    cursor: pointer;
    background-color: rgba(240,240,240,1);
}
.view-entry.light.selected {
    background-color: rgba(240,240,240,1);
}
.view-entry.dark:hover {
    cursor: pointer;
    background-color: rgba(65,65,65,1);
}
.view-entry.dark.selected {
    background-color: rgba(65,65,65,1);
}
.view-entry.in_demo:hover {
    background: repeating-linear-gradient(
      -45deg,
      rgba(75,144,226,0.2),
      rgba(75,144,226,0.2) 10px,
      rgba(75,144,226,0.05) 10px,
      rgba(75,144,226,0.05) 20px
    );
    border-radius: 8px;
    cursor: pointer;
}

/* Alerts and Sheets */
.alert-item.light:hover {
    cursor:pointer;
    background-color: rgba(240,240,240,1)
}
.alert-item.light:active {
    cursor:pointer;
    background-color: rgba(230,230,230,1)
}
.alert-item.dark:hover {
    cursor:pointer;
    background-color: rgba(55,55,55,1)
}
.alert-item.dark:active {
    cursor:pointer;
    background-color: rgba(75,75,75,1)
}

.sheet-item.light:hover {
    cursor:pointer;
    background-color: rgba(240,240,240,1)
}
.sheet-item.light:active {
    cursor:pointer;
    background-color: rgba(230,230,230,1)
}

.sheet-item.dark:hover {
    cursor:pointer;
    background-color: rgba(65,65,65,1)
}
.sheet-item.dark:active {
    cursor:pointer;
    background-color: rgba(85,85,85,1)
}

/* custom file input */
.custom-file {
  height: calc(2.0625rem + 2px);
  font-size: 0.7125rem;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.custom-file-input.light {
  background-color:rgba(245,245,245,1);
}
.custom-file-input.dark {
  background-color:rgba(100,100,100,1);
}
.custom-file-input {
  min-width: 14rem;
  height: calc(2.0625rem + 2px);
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.custom-file-input.light:focus ~ .custom-file-label.light {
  color: rgba(85,85,85,1);
  box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}
.custom-file-input.dark:focus ~ .custom-file-label.dark {
  border-color: rgba(125,125,125,1);
  color: white;
  box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}
.custom-file-input.light:focus ~ .custom-file-label.light::after {
  color: rgba(85,85,85,1);
  background-color: white
}
.custom-file-input.dark:focus ~ .custom-file-label.dark::after {
  border-color: rgba(125,125,125,1);
  color: white;
  background-color: rgba(100,100,100,1);
}
.custom-file-input.dark:focus ~ .custom-file-label.dark:hover {
  border-color: rgba(125,125,125,1);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input:not(:disabled):hover {
  cursor: pointer;
}
.custom-file-input.light:not(:disabled):hover ~ .custom-file-label.light,
.custom-file-input.light:not(:disabled):hover ~ .custom-file-label.light:before {
  background-color: white;
}
.custom-file-input.dark:not(:disabled):hover ~ .custom-file-label.dark,
.custom-file-input.dark:not(:disabled):hover ~ .custom-file-label.dark:before {
  border-color: rgba(125,125,125,1);
  background-color: rgba(100,100,100,1);
}
.custom-file-input.light:disabled + .custom-file-label {
  color: rgba(65,65,65,1);
  background-color: rgba(225,225,225,1);
}
.custom-file-input.dark:disabled + .custom-file-label {
  color: rgba(225,225,225,1);
  background-color: rgba(55,55,55,1);
}
.custom-file-label.light {
  color: rgba(85,85,85,1);
  background-color: rgba(245,245,245,1);
}
.custom-file-label.dark {
  color: white;
  background-color: rgba(85,85,85,1);
}
.custom-file-label {
  border: 1px solid rgba(175,175,175,0.25) !important;
  height: calc(2.0625rem + 2px);
  padding: 0.5rem 1rem;
  line-height: 1.5;
  font-weight: 300;
  box-shadow: none;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border-radius: 8px !important;
  white-space: nowrap;
}
.custom-file-label.light::after {
  color: rgba(85,85,85,1);
  border-left: 1px solid rgba(215,215,215,1);
  background-color: rgba(245,245,245,1);
}
.custom-file-label.dark::after {
  color: white;
  border-left: 1px solid rgba(100,100,100,1);
  background-color: rgba(85,85,85,1);
}
.custom-file-label::after {
  padding: 0.5rem 1rem;
  height: calc(calc(2.0625rem + 2px) - 1px * 2);
  line-height: 1.5;
  border-radius: 0 8px 8px 0;
}

.form-control {
    border-radius: 8px !important;
    background: #edebee;
    background-color: #edebee;
    border-color: rgba(175,175,175,0.25) !important
}
.form-control:focus {
    box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important
}

/* dummy form elements */
.dummy-form {
  border-radius: 8px !important;
  background: #edebee;
  border-color: rgba(175,175,175,0.25) !important
}
.dummy-form:focus {
  box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important
}

/* dummy field elements */
.dummy-field {
  padding: 0.5275rem 0.75rem;
  font-size: 0.7125rem;
  line-height: 1.5;
  font-weight: 500;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border-radius: 8px;
  border: 1px solid rgba(175,175,175,0.25)
}
.dummy-field.light {
  background: rgba(245,245,245,1);
}
.dummy-field.dark {
  background: rgba(85,85,85,1);
}
.dummy-field.light:hover {
  background: rgba(252,252,252,1) !important;
}
.dummy-field.dark:hover {
  background: rgba(100,100,100,0.35) !important;
}
.dummy-field.light.active {
  background: rgba(252,252,252,1) !important;
  border: 1px solid rgba(125,125,125,0.25) !important
  /*box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important*/
}
.dummy-field.dark.active {
  background: rgba(100,100,100,0.35) !important;
  border: 1px solid rgba(175,175,175,0.25) !important
  /*box-shadow: 4px 4px 7px rgba(0,0,0,0.25), -4px -4px 7px rgba(255,255,255,0.025) !important*/
}
.dummy-field-prepend {
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

/* dummy field input elements */
.dummy-field-input:focus {
  border: none !important;
  outline: none !important;
}
.dummy-field-input:-webkit-autofill, .dummy-field-input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--textfield) inset;
  border-top: 1px solid var(--textfield) !important;
  border-bottom: 1px solid var(--textfield) !important;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  -webkit-text-fill-color: var(--text);
}
.dummy-field-input:-webkit-autofill, .dummy-field-input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px var(--textfield) inset;
  border-top: 1px solid var(--textfield) !important;
  border-bottom: 1px solid var(--textfield) !important;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  -webkit-text-fill-color: var(--text);
}
.dummy-field-select {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  appearance: none !important;
  -webkit-box-shadow: none !important;
  -webkit-appearance: none !important;
}
.dummy-field-select:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.dummy-field-select:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* custom scrollbars */
.custom-scrollbars::-webkit-scrollbar {
  height: 8px !important;
  width: 8px !important;
}
.custom-scrollbars::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05) !important;
}
.custom-scrollbars.light::-webkit-scrollbar-track {
  background: rgba(252,252,252,1) !important;
}
.custom-scrollbars.dark::-webkit-scrollbar-track {
  background: rgba(55,55,55,1) !important;
}
.custom-scrollbars::-webkit-scrollbar-thumb {
  background-color: rgba(155,155,155,0.25) !important;
  border-radius: 4px !important;
  border: 1.5px solid rgba(155,155,155,0.4) !important;
}

.custom-select {
    border-radius: 8px !important;
    background: #edebee;
    border-color: rgba(175,175,175,0.25) !important
}
.custom-select:focus {
    box-shadow: none !important
}
.custom-select.light:focus  {
    box-shadow: none !important
}
.custom-select.dark:focus  {
    box-shadow: none !important
}


.avatar {
    border-width: 3px !important
}

.main-sidebar .nav .nav-item.light .nav-link {
    border-bottom: none !important
}

/*
 * Shards Dashboards UI Kit v1.0.0
 * Project URL: https://designrevision.com/downloads/shards-dashboards
 * Based on: Shards UI Kit v2.0.1 (https://designrevision.com/downloads/shards)
 * Based on: Bootstrap v4.0.0 (final) (https://getbootstrap.com)
 * Copyright 2017-* DesignRevision (https://designrevision.com)
 * Copyright 2017-* Catalin Vasile (contact@catalin.me)
 * License: SEE THE LICENSE FILE
 */
/*
 * Shards v2.0.1 (https://designrevision.com/downloads/shards/)
 * Based on: Bootstrap v4.0.0 (final) (https://getbootstrap.com)
 * Copyright 2017-* DesignRevision (https://designrevision.com)
 * Copyright 2017-* Catalin Vasile (http://catalin.me)
 */

:root {
  --blue: #007bff;
  --indigo: #674eec;
  --purple: #8445f7;
  --pink: #ff4169;
  --red: #c4183c;
  --orange: #fb7906;
  --yellow: #ffb400;
  --green: #17c671;
  --teal: #1adba2;
  --cyan: #00b8d8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #3A689C;
  --secondary: #5A6169;
  --success: #17c671;
  --info: #00b8d8;
  --warning: #ffb400;
  --danger: #c4183c;
  --light: #FBFBFB;
  --dark: #212529;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

@media (max-width: 575.98px) {
    html {
        font-size: 15px;
    }
}

html {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
html ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0,0,0,0);
}

/* custom scrollbars */
.custom-scrollbars::-webkit-scrollbar {
  width: 8px !important;
}
.custom-scrollbars::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05) !important;
}
.custom-scrollbars.light::-webkit-scrollbar-track {
  background: rgba(252,252,252,1) !important;
}
.custom-scrollbars.dark::-webkit-scrollbar-track {
  background: rgba(55,55,55,1) !important;
}
.custom-scrollbars::-webkit-scrollbar-thumb {
  background-color: rgba(155,155,155,0.25) !important;
  border-radius: 4px !important;
  border: 1.5px solid rgba(155,155,155,0.4) !important;
}

/* scheduler scrollbars */
.scheduler-view::-webkit-scrollbar {
    width: 12px !important;
}
.scheduler-view::-webkit-scrollbar-track {
    background: rgba(0,0,0,0) !important;
}
.scheduler-view.light::-webkit-scrollbar-track {
    background: rgba(252,252,252,1) !important;
}
.scheduler-view.dark::-webkit-scrollbar-track {
    background: rgba(55,55,55,1) !important;
}
.scheduler-view::-webkit-scrollbar-thumb {
    background-color: rgba(155,155,155,0.25) !important;
    border-radius: 20px !important;
    border: 2px solid rgba(155,155,155,0.4) !important;
}

.page-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
  background: #fff;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 10px solid #19bee1;
}
.page-loader:after {
  content: '';
  background: trasparent;
  width: 130%;
  height: 130%;
  position: absolute;
  border-radius: 100%;
  top: -15%;
  left: -15%;
  opacity: 1;
  box-shadow: rgba(255, 255, 255, 0.75) -4px -5px 3px -3px;
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: rgba(25,25,25,1);
        color: white;
    }
}

.no-wrap {
    display:inline-block;
    max-width:calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.no-wrap.new {
    max-width:calc(100% - 100px);
}

.full-height {
    height:calc(100% - 5px) !important;
    box-shadow: none !important;
    transition: height box-shadow 1000ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.light {
    background-color: rgba(200,200,200,1);
}
.slider.dark {
    background-color: rgba(85,85,85,1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4AA573;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4AA573;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.cursor-grab:hover {
    cursor: grab;
}
.cursor-grabbing:hover {
    cursor: grabbing;
}
.cursor-pointer:hover {
    cursor: pointer
}
.cursor-text-input {
    cursor: text;
}

.disable-text-selection::-moz-selection {
  background: rgba(0,0,0,0) !important;
}

.disable-text-selection::selection {
  background: rgba(0,0,0,0) !important;
}

.pulse-green {
	animation: pulse-green 1.5s infinite;
}

@keyframes pulse-green {
  0% {
    background-color: var(--grey) !important;
    transform: scale(1);
  }
  50% {
    background-color: var(--green) !important;
    transform: scale(1.1);
  }
  100% {
    background-color: var(--grey) !important;
    transform: scale(1);
  }
}

.image-button:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.image-button {
	transition: transform .2s ease-in-out;
}

.image-button:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.text-button {
	transition: opacity .2s ease-in-out;
}

.text-button:hover {
	opacity: 0.75 !important;
	cursor: pointer;
}

a {
    text-decoration: none;
}
  a:hover {
    color: white;
    text-decoration: underline; }

b, strong {
  font-weight: 500; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.75rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400; }

h1, .h1 {
  font-size: 3.052rem;
  letter-spacing: -0.0625rem;
  line-height: 3rem; }

h2, .h2 {
  font-size: 2.441rem;
  letter-spacing: -0.0625rem;
  line-height: 2.25rem; }

h3, .h3 {
  font-size: 1.953rem;
  line-height: 2.25rem; }

h4, .h4 {
  font-size: 1.563rem;
  line-height: 2rem; }

h5, .h5 {
  font-size: 1.25rem;
  line-height: 1.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.5rem; }

.lead {
  line-height: 1.875rem; }

.display-1,
.display-2,
.display-3,
.display-4 {
  margin-bottom: 0.75rem; }

.display-1 {
  font-size: 7.451rem;
  line-height: 1; }

.display-2 {
  font-size: 5.96rem;
  line-height: 1; }

.display-3 {
  font-size: 4.768rem;
  line-height: 1; }

.display-4 {
  font-size: 3.815rem;
  line-height: 1; }

p {
  margin-bottom: 1.75rem; }

hr {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
}

hr.light {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

hr.dark {
    border-top: 1px solid rgba(225, 225, 225, 0.25);
}

small,
.small {
  font-size: 80%;
  font-weight: 300; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fff09e; }

.blockquote {
  margin-bottom: 0.75rem;
  font-size: 1.5rem; }

.blockquote-footer {
  font-size: 1.125rem; }

.img-thumbnail {
  padding: 0;
  border: none;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: none; }

.figure-img {
  margin-bottom: 0.75rem; }

.figure-caption {
  font-size: 1rem;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 0.75rem;
  padding: 0.1875rem 0.7125rem; }

kbd {
  padding: 0.1875rem 0.7125rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #212529;
  border-radius: 0.625rem;
  box-shadow: none; }
  kbd kbd {
    font-weight: 500; }

pre {
  margin-bottom: .75rem;
  font-size: 0.75rem;
  color: #212529;
  line-height: 1.375rem; }

.pre-scrollable {
  max-height: 340px; }

.table {
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d1d3d5; }

.table-hover .table-secondary:hover {
  background-color: #c4c6c9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c4c6c9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #beefd7; }

.table-hover .table-success:hover {
  background-color: #aaeaca; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #aaeaca; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ebf4; }

.table-hover .table-info:hover {
  background-color: #a2e5f1; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2e5f1; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeab8; }

.table-hover .table-warning:hover {
  background-color: #ffe29f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe29f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #eebec8; }

.table-hover .table-danger:hover {
  background-color: #e9aab7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e9aab7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c2c3; }

.table-hover .table-dark:hover {
  background-color: #b4b5b6; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b5b6; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

.form-control.light {
    color: rgba(85,85,85,1);
    background-color: rgba(245,245,245,1);
    border: 1px solid rgba(215,215,215,1);
}
.form-control.dark {
    color: white;
    background-color: rgba(85,85,85,1);
    border: 1px solid rgba(100,100,100,1);
}
.form-control {
  padding: 0.5275rem 0.75rem;
  font-size: 0.7125rem;
  line-height: 1.5;
  font-weight: 500;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06) }
  .form-control:not(textarea) {
      height:35px !important;
  }
  .form-control.light:hover {
    background-color: white;
    border-color: #6CBF66; }
  .form-control.dark:hover {
    background-color: rgba(100,100,100,1);
    border-color: rgba(125,125,125,1); }
  .form-control:focus {
    border-color: #6CBF66;
    box-shadow: 0 0.313rem 0.719rem rgba(108,191,102,0.25), 0 0.156rem 0.125rem rgba(108,191,102,0.25); }
    .form-control.light:focus {
      background-color:white}
      .form-control.dark:focus {
        background-color:rgba(100,100,100,1)}
    .form-control:focus:hover {
      border-color: #6CBF66; }
      .form-control.WEAK:focus:hover {
          box-shadow: 0 0.313rem 0.719rem rgba(248,93,87,0.25);
          border-color: #F85D57; }
        .form-control.GOOD:focus:hover {
            box-shadow: 0 0.313rem 0.719rem rgba(234,210,22,0.25);
            border-color: #EAD216; }
          .form-control.STRONG:focus:hover {
            box-shadow: 0 0.313rem 0.719rem rgba(108,191,102,0.25);
            border-color: #6CBF66; }
  .form-control::placeholder {
    color: rgba(150,150,150,1); }
  .form-control.light:disabled, .form-control[readonly] {
    background-color: rgba(225,225,225,1); }
    .form-control.dark:disabled, .form-control[readonly] {
      background-color: rgba(65,65,65,1); }
    .form-control:disabled:hover, .form-control[readonly]:hover {
      border-color: #F85D57;
      cursor: not-allowed; }
  .form-control[readonly]:not(:disabled):focus {
    box-shadow: none;
    border-color: rgba(200,200,200,1); }

.dummy-form.light {
    color: rgba(85,85,85,1);
    background-color: rgba(245,245,245,1);
    border: 1px solid rgba(215,215,215,1);
}
.dummy-form.dark {
    color: white;
    background-color: rgba(85,85,85,1);
    border: 1px solid rgba(100,100,100,1);
}
.dummy-form{
  padding: 0.5275rem 0.75rem;
  font-size: 0.7125rem;
  line-height: 1.5;
  font-weight: 500;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06) }
  .dummy-form:not(textarea) {
      min-height:35px !important;
  }
  .dummy-form.light:hover {
    cursor: pointer;
    background-color: white;
    border-color: #6CBF66; }
  .dummy-form.dark:hover {
    cursor: pointer;
    background-color: rgba(100,100,100,1);
    border-color: rgba(125,125,125,1); }
  .dummy-form:focus {
    border-color: #6CBF66;
    box-shadow: 0 0.313rem 0.719rem rgba(108,191,102,0.25), 0 0.156rem 0.125rem rgba(108,191,102,0.25); }
    .dummy-form.light:focus {
      background-color:white}
      .dummy-form.dark:focus {
        background-color:rgba(100,100,100,1)}
    .dummy-form:focus:hover {
      border-color: #6CBF66; }
      .dummy-form.WEAK:focus:hover {
          box-shadow: 0 0.313rem 0.719rem rgba(248,93,87,0.25);
          border-color: #F85D57; }
        .dummy-form.GOOD:focus:hover {
            box-shadow: 0 0.313rem 0.719rem rgba(234,210,22,0.25);
            border-color: #EAD216; }
          .dummy-form.STRONG:focus:hover {
            box-shadow: 0 0.313rem 0.719rem rgba(108,191,102,0.25);
            border-color: #6CBF66; }
  .dummy-form::placeholder {
    color: rgba(150,150,150,1); }
  .dummy-form.light:disabled, .dummy-form[readonly] {
    background-color: rgba(225,225,225,1); }
    .dummy-form.dark:disabled, .dummy-form[readonly] {
      background-color: rgba(65,65,65,1); }
    .dummy-form:disabled:hover, .dummy-form[readonly]:hover {
      border-color: #F85D57;
      cursor: not-allowed; }
  .dummy-form[readonly]:not(:disabled):focus {
    box-shadow: none;
    border-color: rgba(200,200,200,1); }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.09375rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

select.form-control:hover {
  cursor: pointer; }

form label:hover {
  cursor: pointer; }

.col-form-label {
  padding-top: calc(0.5275rem + 1px);
  padding-bottom: calc(0.5275rem + 1px);
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.7125rem + 1px);
  padding-bottom: calc(0.7125rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.4286rem + 1px);
  padding-bottom: calc(0.4286rem + 1px);
  font-size: 0.6875rem;
  line-height: 1.5; }

.form-control-plaintext {
  padding-top: 0.5275rem;
  padding-bottom: 0.5275rem;
  line-height: 1.5;
  font-weight: 300; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-middle > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4286rem 0.625rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-middle > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.88845rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-middle > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.7125rem 0.7125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-middle > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.9375rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  margin-top: 0.25rem; }

.form-check {
  padding-left: 1.25rem; }

.form-check-input {
  margin-top: 0.313rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-inline {
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    margin-right: 0.3125rem; }

.valid-feedback {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #17c671; }

.valid-tooltip {
  background-color: rgba(23, 198, 113, 0.8); }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #17c671;
  box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1), 0 1px 1px 0.1rem rgba(23, 198, 113, 0.2); }
  .was-validated .form-control:valid:hover, .form-control.is-valid:hover, .was-validated
  .custom-select:valid:hover,
  .custom-select.is-valid:hover {
    border-color: #17c671; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #17c671; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #17c671; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #57eca4;
    border-color: #2ae68b; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #2ae68b; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0.313rem 0.719rem rgba(23, 198, 113, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  color: #17c671;
  border-color: #17c671; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    background-color: #b3f6d5;
    border-color: #2ae68b;
    color: #17c671; }

.was-validated .custom-file-input:focus:valid ~ .custom-file-label, .custom-file-input:focus.is-valid ~ .custom-file-label {
  border-color: #17c671;
  box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1), 0 1px 1px 0.1rem rgba(23, 198, 113, 0.2); }

.was-validated .custom-file-input:hover:valid ~ .custom-file-label, .custom-file-input:hover.is-valid ~ .custom-file-label {
  border-color: #17c671; }

.was-validated .custom-toggle .custom-control-input:not(:checked):valid ~ .custom-control-label::before, .custom-toggle .custom-control-input:not(:checked).is-valid ~ .custom-control-label::before {
  background-color: #fff; }

.was-validated .custom-toggle .custom-control-input:valid ~ .custom-control-label::before, .custom-toggle .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #17c671; }

.was-validated .custom-toggle .custom-control-input:invalid ~ .custom-control-label::after, .custom-toggle .custom-control-input.is-invalid ~ .custom-control-label::after {
  background-color: #eb8c95; }

.was-validated .custom-toggle .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-toggle .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0.313rem 0.719rem rgba(23, 198, 113, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }

.invalid-feedback {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c4183c; }

.invalid-tooltip {
  background-color: rgba(196, 24, 60, 0.8); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #c4183c;
  box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1), 0 1px 1px 0.1rem rgba(196, 24, 60, 0.2); }
  .was-validated .form-control:invalid:hover, .form-control.is-invalid:hover, .was-validated
  .custom-select:invalid:hover,
  .custom-select.is-invalid:hover {
    border-color: #c4183c; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c4183c; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #c4183c; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ea5876;
    border-color: #e52a51; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e52a51; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0.313rem 0.719rem rgba(196, 24, 60, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  color: #c4183c;
  border-color: #c4183c; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    background-color: #f6b2c0;
    border-color: #e52a51;
    color: #c4183c; }

.was-validated .custom-file-input:focus:invalid ~ .custom-file-label, .custom-file-input:focus.is-invalid ~ .custom-file-label {
  border-color: #c4183c;
  box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1), 0 1px 1px 0.1rem rgba(196, 24, 60, 0.2); }

.was-validated .custom-file-input:hover:invalid ~ .custom-file-label, .custom-file-input:hover.is-invalid ~ .custom-file-label {
  border-color: #c4183c; }

.was-validated .custom-toggle .custom-control-input:not(:checked):invalid ~ .custom-control-label::before, .custom-toggle .custom-control-input:not(:checked).is-invalid ~ .custom-control-label::before {
  background-color: #fff; }

.was-validated .custom-toggle .custom-control-input:valid ~ .custom-control-label::before, .custom-toggle .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #17c671; }

.was-validated .custom-toggle .custom-control-input:invalid ~ .custom-control-label::after, .custom-toggle .custom-control-input.is-invalid ~ .custom-control-label::after {
  background-color: #eb8c95; }

.was-validated .custom-toggle .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-toggle .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0.313rem 0.719rem rgba(196, 24, 60, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }

@media (min-width: 576px) {
  .form-inline .form-check-input {
    margin-right: 0.313rem; } }

.btn {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  border: 1px solid transparent;
  padding: 0.5625rem 1rem;
  font-size: 0.75rem;
  line-height: 1.125;
  border-radius: 0.25rem;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .btn:hover, .btn.hover {
    cursor: pointer; }
  .btn:focus, .btn.focus {
    box-shadow: none; }
  .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none;
    box-shadow: none; }
  .btn.btn-squared {
    border-radius: 0; }
  .btn.btn-pill {
    border-radius: 50px; }

.btn-primary {
  color: #fff;
	font-weight:600;
  border-color: #6CBF66;
  background-color: #6CBF66;
  box-shadow: none; }
  .btn-primary:hover {
    color: #fff;
    background-color: #57A851;
    border-color: #57A851;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25); }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #659861;
    border-color: #659861;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #489643;
    border-color: #489643;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-clear {
  color: #fff;
  border-color: rgba(255,255,255,0.25);
  background-color: rgba(0,0,0,0);
  box-shadow: none; }
  .btn-clear:hover {
    color: #fff;
    background-color: rgba(102,179,128,0.25);
    border-color: rgba(102,179,128,0.25);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 97, 0, 0.25); }
  .btn-clear:focus, .btn-clear.focus {
    box-shadow: 0 0 0 3px rgba(0, 97, 0, 0.15), 0 3px 15px rgba(0, 97, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-clear.disabled, .btn-clear:disabled {
    background-color: #A1BD95;
    border-color: #A1BD95;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-clear:not(:disabled):not(.disabled):active, .btn-seconcleardary:not(:disabled):not(.disabled).active,
  .show > .btn-clear.dropdown-toggle {
    color: #fff;
    background-color: #7AC393;
    border-color: #7AC393;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-secondary {
  color: #fff;
	font-weight:600;
  border-color: #5AA473;
  background-color: #5AA473;
  box-shadow: none; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #458B5D;
    border-color: #458B5D;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25); }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #458B5D;
    border-color: #458B5D;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #458B5D;
    border-color: #458B5D;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

    .btn-dark-green {
          color: white;
          font-weight:600;
          border: 1px solid #3C8736;
          background-color: #3C8736;
          box-shadow: none; }
          .btn-dark-green:hover {
            background-color: #256D1F;
            border-color: #256D1F;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25); }
          .btn-dark-green:focus, .btn-dark-green.focus {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
          .btn-dark-green.disabled, .btn-dark-green:disabled {
            background-color: #256D1F;
            border-color: #256D1F;
            box-shadow: none;
            cursor: not-allowed; }
          .btn-dark-green:not(:disabled):not(.disabled):active, .btn-dark-green:not(:disabled):not(.disabled).active,
          .show > .btn-dark-green.dropdown-toggle {
            background-color: #256D1F;
            border-color: #256D1F;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }



.btn-third {
    color: rgba(108,191,102,1);
    font-weight:600;
    border: 2px solid rgba(108,191,102,1);
    background-color: rgba(0,0,0,0);
    box-shadow: none; }
.btn-third:hover {
    color: #fff;
    background-color: rgba(108,191,102,1);
    border-color: rgba(108,191,102,1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25); }
.btn-third:focus, .btn-third.focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
.btn-third.disabled, .btn-third:disabled {
    background-color: #659861;
    border-color: #659861;
    box-shadow: none;
    cursor: not-allowed; }
.btn-third:not(:disabled):not(.disabled):active, .btn-third:not(:disabled):not(.disabled).active,
.show > .btn-third.dropdown-toggle {
    color: #fff;
    background-color: #489643;
    border-color: #489643;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

    .btn-white {
        color: white;
        font-weight:600;
        border: 2px solid white;
        background-color: rgba(0,0,0,0);
        box-shadow: none; }
    .btn-white:hover {
        background-color:rgba(255,255,255,0.25);
        transform: scale(1.05,1.05);
    }

.btn-success {
  color: #fff;
  border-color: #17c671;
  background-color: #17c671;
  box-shadow: none; }
  .btn-success:hover {
    color: #fff;
    background-color: #14af64;
    border-color: #14af64;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(23, 198, 113, 0.25); }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(23, 198, 113, 0.15), 0 3px 15px rgba(23, 198, 113, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #17c671;
    border-color: #17c671;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #14af64;
    border-color: #129857;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-info {
  color: #fff;
  border-color: #00b8d8;
  background-color: #00b8d8;
  box-shadow: none; }
  .btn-info:hover {
    color: #fff;
    background-color: #00a2bf;
    border-color: #00a2bf;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 184, 216, 0.25); }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(0, 184, 216, 0.15), 0 3px 15px rgba(0, 184, 216, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #00b8d8;
    border-color: #00b8d8;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00a2bf;
    border-color: #008da5;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-warning {
  color: #212529;
  border-color: #ffb400;
  background-color: #ffb400;
  box-shadow: none; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e6a200;
    border-color: #e6a200;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 180, 0, 0.25); }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 180, 0, 0.15), 0 3px 15px rgba(255, 180, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffb400;
    border-color: #ffb400;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e6a200;
    border-color: #cc9000;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-danger {
  color: #fff;
	font-weight:600;
  border-color: #FF5B51;
  background-color: #FF5B51;
  box-shadow: none; }
  .btn-danger:hover {
    color: #fff;
    background-color: #E34A39;
    border-color: #D94231;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(196, 24, 60, 0.25); }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(196, 24, 60, 0.15), 0 3px 15px rgba(196, 24, 60, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #c4183c;
    border-color: #c4183c;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ad1535;
    border-color: #97122e;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-light {
  color: #212529;
  border-color: #FBFBFB;
  background-color: #FBFBFB;
  box-shadow: none; }
  .btn-light:hover {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(251, 251, 251, 0.25); }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(251, 251, 251, 0.15), 0 3px 15px rgba(251, 251, 251, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #FBFBFB;
    border-color: #FBFBFB;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #eeeeee;
    border-color: #e2e2e2;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-dark {
  color: #fff;
  border-color: #4A4A4A;
  background-color: #4A4A4A;
  box-shadow: none; }
  .btn-dark:hover {
    color: #fff;
    background-color: #383737;
    border-color: #383737;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(33, 37, 41, 0.25); }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.15), 0 3px 15px rgba(33, 37, 41, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #383737;
    border-color: #383737;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #383737;
    border-color: #0a0c0d;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-black {
  color: #fff;
  border-color: #000;
  background-color: #000;
  box-shadow: none; }
  .btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25); }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-black.disabled, .btn-black:disabled {
    background-color: #000;
    border-color: #000;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-primary {
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
  color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25); }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-secondary {
  background-color: transparent;
  background-image: none;
  border-color: #5A6169;
  color: #5A6169; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #5A6169;
    border-color: #5A6169;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(90, 97, 105, 0.25); }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15), 0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #5A6169;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5A6169;
    border-color: #5A6169; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-success {
  background-color: transparent;
  background-image: none;
  border-color: #17c671;
  color: #17c671; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #17c671;
    border-color: #17c671;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(23, 198, 113, 0.25); }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(23, 198, 113, 0.15), 0 3px 15px rgba(23, 198, 113, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #17c671;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #17c671;
    border-color: #17c671; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-info {
  background-color: transparent;
  background-image: none;
  border-color: #00b8d8;
  color: #00b8d8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00b8d8;
    border-color: #00b8d8;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 184, 216, 0.25); }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(0, 184, 216, 0.15), 0 3px 15px rgba(0, 184, 216, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00b8d8;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00b8d8;
    border-color: #00b8d8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-warning {
  background-color: transparent;
  background-image: none;
  border-color: #ffb400;
  color: #ffb400; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffb400;
    border-color: #ffb400;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 180, 0, 0.25); }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 180, 0, 0.15), 0 3px 15px rgba(255, 180, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffb400;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffb400;
    border-color: #ffb400; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-danger {
  background-color: transparent;
  background-image: none;
  border-color: #c4183c;
  color: #c4183c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #c4183c;
    border-color: #c4183c;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(196, 24, 60, 0.25); }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(196, 24, 60, 0.15), 0 3px 15px rgba(196, 24, 60, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #c4183c;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #c4183c;
    border-color: #c4183c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-light {
  background-color: transparent;
  background-image: none;
  border-color: #FBFBFB;
  color: #212529; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FBFBFB;
    border-color: #FBFBFB;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(251, 251, 251, 0.25); }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(251, 251, 251, 0.15), 0 3px 15px rgba(251, 251, 251, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FBFBFB;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FBFBFB;
    border-color: #FBFBFB; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-dark {
  background-color: transparent;
  background-image: none;
  border-color: #212529;
  color: #212529; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(33, 37, 41, 0.25); }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.15), 0 3px 15px rgba(33, 37, 41, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #212529;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-white {
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  color: #212529;
  color: #fff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 255, 255, 0.25); }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15), 0 3px 15px rgba(255, 255, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active {
    color: #000; }

.btn-outline-black {
  background-color: transparent;
  background-image: none;
  border-color: #000;
  color: #000;
  color: #000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25); }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active {
    color: #fff; }

.btn-link {
  font-weight: 300;
  color: #007bff; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled {
    color: #868e96; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 1.75rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4286rem 0.875rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

.fade {
  transition: opacity 200ms ease-in-out; }

.collapsing {
  transition: height 350ms ease-in-out; }

i.material-icons {
  font-size: inherit;
  position: relative;
  top: 2px; }

.dropdown-menu.light {
    background-color: rgba(245,245,245,1);
}
.dropdown-menu.dark {
    background-color: rgba(35,35,35,1);
}

.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 10px 0;
  margin: 0 0 0;
  font-size: 1rem;
  /*border: 1px solid rgba(0, 0, 0, 0.05);*/
  border-radius: 0.375rem;
  /*box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06); */
}
  .dropdown-menu.light {
      color: rgba(85,85,85,1);
  }
  .dropdown-menu.dark {
      color: white;
  }
  .dropdown-menu-small {
    /*box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.11), 0 3px 10px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);*/
    /*padding: 0.25rem 0;*/
    font-size: 0.813rem;
}
    .dropdown-menu-small .dropdown-item {
      /*padding: 0.375rem 0.875rem;*/
      font-size: 0.813rem;
  }

.dropup .dropdown-menu {
  margin-bottom: 0; }

.dropright .dropdown-menu {
  margin-left: 0; }

.dropleft .dropdown-menu {
  margin-right: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(65,65,65,1); }

.dropdown-item {
  padding: 0.5rem 1.25rem;
  font-weight: 300;
  color: #FFFFFF;
  font-size: 0.9375rem;
  background-color: rgba(0,0,0,0);
  /*transition: background-color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);*/
}
.dropdown-item.light:hover, .dropdown-item.light:focus {
    color: #FFFFFF;
    background-color: rgba(245,245,245,1);
}
.dropdown-item.dark:hover, .dropdown-item.dark:focus {
  color: #FFFFFF;
  background-color: rgba(85,85,85,1);
}
.dropdown-item.light.active, .dropdown-item.light:active {
    color: #fff;
    background-color: rgba(245,245,245,1);
}
.dropdown-item.dark.active, .dropdown-item.dark:active {
    color: #fff;
    background-color: rgba(85,85,85,1);
}
.dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
}
    .dropdown-item.disabled:hover, .dropdown-item:disabled:hover {
      background: none;
      cursor: not-allowed;
  }

.dropdown-header {
  padding: 10px 1.25rem;
  font-size: 0.6875rem;
  color: #868e96; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px; }

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px; }

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group.input-group-seamless > .form-control {
  border-radius: 0.25rem; }

.input-group.input-group-seamless > .input-group-append,
.input-group.input-group-seamless > .input-group-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4; }
  .input-group.input-group-seamless > .input-group-append .input-group-text,
  .input-group.input-group-seamless > .input-group-prepend .input-group-text {
    padding: 0.75rem 0.625rem;
    background: transparent;
    border: none; }

.input-group.input-group-seamless > .input-group-append {
  right: 0; }

.input-group.input-group-seamless > .input-group-middle {
  right: 0;
  left: 0; }

.input-group.input-group-seamless > .input-group-prepend {
  left: 0; }

.input-group.input-group-seamless > .form-control:not(:last-child),
.input-group.input-group-seamless > .custom-select:not(:last-child) {
  padding-right: 40px; }

.input-group.input-group-seamless > .form-control:not(:first-child),
.input-group.input-group-seamless > .custom-select:not(:first-child) {
  padding-left: 40px; }

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  border-radius: 0.25rem;
  width:40px;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.input-group-text.light {
    color: rgba(200,200,200,1);
    background-color: rgba(245,245,245,1);
    border: 1px solid rgba(225,225,225,1);
}
.input-group-text.dark {
    color: rgba(200,200,200,1);
    background-color: rgba(85,85,85,1);
    border: 1px solid rgba(100,100,100,1);
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .input-group-middle > .btn,
.input-group > .input-group-middle > .input-group-text {
  border-left: 0;
  border-right: 0;
  border-radius: 0; }

.input-group-middle {
  display: flex; }

.custom-control {
  min-height: 1.5rem;
  padding-left: 1.688rem; }
  .custom-control:hover {
    cursor: pointer; }
  .custom-control .custom-control-label:before {
    pointer-events: all; }

.custom-control-inline {
  margin-right: 1rem; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: #007bff;
  box-shadow: none; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  box-shadow: none; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #868e96; }
  .custom-control-input:disabled ~ .custom-control-label:hover {
    cursor: not-allowed; }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef; }

.custom-control-label:hover {
  cursor: pointer; }

.custom-control-label::before {
  top: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  box-shadow: none; }

.custom-control-label::after {
  top: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 2px; }

.custom-checkbox .custom-control-label::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 7px;
  width: 5px;
  height: 11px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: 100ms; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: none; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
  background-image: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border: none;
  background-color: #007bff;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  content: '';
  position: absolute;
  transform: scale(1);
  background-image: none;
  background-color: #fff;
  border: none;
  width: 10px;
  height: 2px;
  top: 11px;
  left: 4px;
  opacity: 1;
  transition: none; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: #e9ecef;
  border-color: #e1e5eb; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::after {
  border-color: #e1e5eb; }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-label::after {
  content: '';
  border-radius: 50%;
  transform: scale(0);
  background-image: none !important;
  position: absolute;
  background: #fff;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 5px;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: .1s;
  opacity: 0;
  transform: scale(0); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
  transform: scale(1); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #a8aeb4; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: #e9ecef;
  border-color: #e1e5eb; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background: #e1e5eb; }

.custom-select.light {
    color: rgba(85,85,85,1);
    background: rgba(245,245,245,1) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid rgba(210,210,210,1);
}

.custom-select.dark {
    color: white;
    background: rgba(55,55,55,1) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid rgba(100,100,100,1);
}

.custom-select {
  height: calc(2.09375rem);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  font-weight: 500;
  font-size: 12px;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border-radius: 0.25rem; }
  .custom-select:focus {
    border-color: #6CBF66;
    box-shadow: 0 0.313rem 0.719rem rgba(0, 0, 0, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }
  .custom-select.light:hover:not(:focus):not(:disabled) {
    cursor: pointer;
    border-color: #6CBF66;
    background-color: white;
}
.custom-select.dark:hover:not(:focus):not(:disabled) {
  cursor: pointer;
  border-color: #6CBF66;
  background-color: rgba(100,100,100,1);
}
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    padding-right: 0.75rem; }
  .custom-select:disabled, .custom-select[readonly] {
    background-color: rgba(225,225,225,1); }
    .custom-select:disabled:hover, .custom-select[readonly]:hover {
      border-color: #F85D57;
      cursor: not-allowed; }

.custom-select-sm {
  height: calc(1.88845rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: calc(2.9375rem + 2px);
  font-size: 1.25rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.custom-toggle {
  position: relative;
  padding-left: 3.75rem; }
  .custom-toggle .custom-control-label::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 3.125rem;
    height: 1.75rem;
    border-radius: 100px;
    border: 0.0625rem solid #e1e5eb; }
  .custom-toggle .custom-control-label::after {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    background: #e1e5eb;
    border-radius: 6.25rem;
    transition: 350ms; }
  .custom-toggle .custom-control-input:checked ~ .custom-control-label::before {
    background: #17c671;
    border-color: #17c671; }
  .custom-toggle .custom-control-input:checked ~ .custom-control-label::after {
    left: 2.875rem;
    transform: translateX(-100%);
    background: #fff; }
  .custom-toggle .custom-control-input:checked:disabled ~ .custom-control-label::before {
    background: #e9ecef;
    border-color: #e1e5eb; }
  .custom-toggle .custom-control-input:checked:disabled ~ .custom-control-label::after {
    background: #e1e5eb; }
  .custom-toggle .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
    width: 1.625rem; }
  .custom-toggle .custom-control-input:active:not(:checked) ~ .custom-control-label::before {
    background-color: #fff; }
  .custom-toggle .custom-control-input:disabled:active ~ .custom-control-label::before {
    background-color: #e9ecef; }
  .custom-toggle .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0.313rem 0.719rem rgba(23, 198, 113, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }
  .custom-toggle .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }
  .custom-toggle.custom-toggle-sm {
    padding-left: 2.625rem; }
    .custom-toggle.custom-toggle-sm .custom-control-label::before {
      top: .1875rem;
      position: absolute;
      display: block;
      width: 2.1875rem;
      height: 1.125rem;
      background: #fff;
      border-radius: 100px;
      border: 0.0625rem solid #e1e5eb; }
    .custom-toggle.custom-toggle-sm .custom-control-label::after {
      content: '';
      position: absolute;
      top: .375rem;
      left: 0.1875rem;
      width: 0.75rem;
      height: 0.75rem; }
    .custom-toggle.custom-toggle-sm .custom-control-input:checked ~ .custom-control-label::after {
      left: 1.9375rem; }
    .custom-toggle.custom-toggle-sm .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
      width: 1rem; }

.nav {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  transition: background-color 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.nav-wrapper.light {
    background-color:white;
}
.nav-wrapper.dark {
    background-color:rgba(25,25,25,1);
}

.nav-button {
     height:20px;
     width:26.6px;
     margin-left:5px;
     transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.seeds {
     height:30px;
     width:26.25px;
     margin-left:5px;
     transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.nav-back-arrow {
     height:20px;
     width:12.5px;
     transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.nav-link {
  padding: 0.625rem 0.625rem;
	color: white;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .nav-link.disabled {
    color: rgba(125,125,125,1); }

.nav-tabs {
  border-bottom: 1px solid rgba(45,45,45,1); }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: rgba(45,45,45,1); }
    .nav-tabs .nav-link.disabled {
      color: #868e96; }
      .nav-tabs .nav-link.disabled:hover {
        cursor: not-allowed;
        border-color: transparent; }
    .nav-tabs .nav-link:hover {
      border-color: rgba(35,35,35,1); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #FFFFFF;
    background-color: rgba(245,245,245,1);
    border-color: rgba(245,245,245,1); }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.375rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: white; }

.nav-pills:hover {
  background-color: #fdfdfd; }

.nav-outlined-pills .nav-link {
  border-radius: 0.375rem;
  border: 1px solid transparent; }
  .nav-outlined-pills .nav-link.active,
  .show > .nav-outlined-pills .nav-link {
    background: none;
    color: white;
    border-color: white; }
  .nav-outlined-pills .nav-link:hover {
    border-color: rgba(245,245,245,1); }

.nav-blue .nav-link.active {
  background-color: white;
  border-color: rgba(245,245,245,1);
  color: white; }

.nav-blue .nav-link.disabled {
  color: #868e96; }
  .nav-blue .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-blue .nav-link {
  color: white; }

.nav-blue.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #3395ff;
  color: #007bff; }
  .nav-blue.nav-outlined-pills .nav-link.active:hover {
    border-color: white; }

.nav-blue.nav-outlined-pills .nav-link {
  color: #007bff; }

.nav-indigo .nav-link.active {
  background-color: #674eec;
  border-color: #5b40eb;
  color: #fff; }

.nav-indigo .nav-link.disabled {
  color: #868e96; }
  .nav-indigo .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-indigo .nav-link {
  color: #674eec; }

.nav-indigo.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #8f7cf1;
  color: #674eec; }
  .nav-indigo.nav-outlined-pills .nav-link.active:hover {
    border-color: #8f7cf1; }

.nav-indigo.nav-outlined-pills .nav-link {
  color: #674eec; }

.nav-purple .nav-link.active {
  background-color: #8445f7;
  border-color: #7a36f6;
  color: #fff; }

.nav-purple .nav-link.disabled {
  color: #868e96; }
  .nav-purple .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-purple .nav-link {
  color: #8445f7; }

.nav-purple.nav-outlined-pills .nav-link.active {
  border-color: #a476f9;
  color: #8445f7; }
  .nav-purple.nav-outlined-pills .nav-link.active:hover {
    border-color: #a476f9; }

.nav-purple.nav-outlined-pills .nav-link {
  color: #8445f7; }

.nav-pink .nav-link.active {
  background-color: #ff4169;
  border-color: #ff325d;
  color: #fff; }

.nav-pink .nav-link.disabled {
  color: #868e96; }
  .nav-pink .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-pink .nav-link {
  color: #ff4169; }

.nav-pink.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #ff7491;
  color: #ff4169; }
  .nav-pink.nav-outlined-pills .nav-link.active:hover {
    border-color: #ff7491; }

.nav-pink.nav-outlined-pills .nav-link {
  color: #ff4169; }

.nav-red .nav-link.active {
  background-color: #c4183c;
  border-color: #b61638;
  color: #fff; }

.nav-red .nav-link.disabled {
  color: #868e96; }
  .nav-red .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-red .nav-link {
  color: #c4183c; }

.nav-red.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #e52a51;
  color: #c4183c; }
  .nav-red.nav-outlined-pills .nav-link.active:hover {
    border-color: #e52a51; }

.nav-red.nav-outlined-pills .nav-link {
  color: #c4183c; }

.nav-orange .nav-link.active {
  background-color: #fb7906;
  border-color: #ee7204;
  color: #fff; }

.nav-orange .nav-link.disabled {
  color: #868e96; }
  .nav-orange .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-orange .nav-link {
  color: #fb7906; }

.nav-orange.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #fc9438;
  color: #fb7906; }
  .nav-orange.nav-outlined-pills .nav-link.active:hover {
    border-color: #fc9438; }

.nav-orange.nav-outlined-pills .nav-link {
  color: #fb7906; }

.nav-yellow .nav-link.active {
  background-color: #ffb400;
  border-color: #f0a900;
  color: #212529; }

.nav-yellow .nav-link.disabled {
  color: #868e96; }
  .nav-yellow .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-yellow .nav-link {
  color: #ffb400; }

.nav-yellow.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #ffc333;
  color: #ffb400; }
  .nav-yellow.nav-outlined-pills .nav-link.active:hover {
    border-color: #ffc333; }

.nav-yellow.nav-outlined-pills .nav-link {
  color: #ffb400; }

.nav-green .nav-link.active {
  background-color: #17c671;
  border-color: #15b869;
  color: #fff; }

.nav-green .nav-link.disabled {
  color: #868e96; }
  .nav-green .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-green .nav-link {
  color: #17c671; }

.nav-green.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #2ae68b;
  color: #17c671; }
  .nav-green.nav-outlined-pills .nav-link.active:hover {
    border-color: #2ae68b; }

.nav-green.nav-outlined-pills .nav-link {
  color: #17c671; }

.nav-teal .nav-link.active {
  background-color: #1adba2;
  border-color: #18cd98;
  color: #212529; }

.nav-teal .nav-link.disabled {
  color: #868e96; }
  .nav-teal .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-teal .nav-link {
  color: #1adba2; }

.nav-teal.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #40e8b7;
  color: #1adba2; }
  .nav-teal.nav-outlined-pills .nav-link.active:hover {
    border-color: #40e8b7; }

.nav-teal.nav-outlined-pills .nav-link {
  color: #1adba2; }

.nav-cyan .nav-link.active {
  background-color: #00b8d8;
  border-color: #00abc9;
  color: #fff; }

.nav-cyan .nav-link.disabled {
  color: #868e96; }
  .nav-cyan .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-cyan .nav-link {
  color: #00b8d8; }

.nav-cyan.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #0cdbff;
  color: #00b8d8; }
  .nav-cyan.nav-outlined-pills .nav-link.active:hover {
    border-color: #0cdbff; }

.nav-cyan.nav-outlined-pills .nav-link {
  color: #00b8d8; }

.nav-white .nav-link.active {
  background-color: rgba(245,245,245,1);
  border-color: #f7f7f7;
  color: #212529; }

.nav-white .nav-link.disabled {
  color: #868e96; }
  .nav-white .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-white .nav-link {
  color: #fff; }

.nav-white.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: white;
  color: #fff; }
  .nav-white.nav-outlined-pills .nav-link.active:hover {
    border-color: white; }

.nav-white.nav-outlined-pills .nav-link {
  color: #fff; }

.nav-gray .nav-link.active {
  background-color: #868e96;
  border-color: #7e868f;
  color: #fff; }

.nav-gray .nav-link.disabled {
  color: #868e96; }
  .nav-gray .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-gray .nav-link {
  color: #868e96; }

.nav-gray.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #a1a8ae;
  color: #868e96; }
  .nav-gray.nav-outlined-pills .nav-link.active:hover {
    border-color: #a1a8ae; }

.nav-gray.nav-outlined-pills .nav-link {
  color: #868e96; }

.nav-gray-dark .nav-link.active {
  background-color: #343a40;
  border-color: #2d3238;
  color: #fff; }

.nav-gray-dark .nav-link.disabled {
  color: #868e96; }
  .nav-gray-dark .nav-link.disabled:hover {
    cursor: not-allowed;
    border-color: transparent; }

.nav-gray-dark .nav-link {
  color: #343a40; }

.nav-gray-dark.nav-outlined-pills .nav-link.active {
  background: transparent;
  border-color: #4b545c;
  color: #343a40; }
  .nav-gray-dark.nav-outlined-pills .nav-link.active:hover {
    border-color: #4b545c; }

.nav-gray-dark.nav-outlined-pills .nav-link {
  color: #343a40; }

.navbar {
  padding: 0.75rem 1.5rem;
}

.navbar.light {
    background-color: white;
}

.navbar.dark {
    background-color: rgba(25,25,25,1);
}

.navbar-brand {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-right: 1.5rem;
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400; }

.navbar-text {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: none;
  background: none; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
  background: none; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: white; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: white; }

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0.46875rem 1.1875rem rgba(0, 0, 0, 0.1);
  transition: background-color 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem; }
  .card .list-group-item {
    padding: 0.7125rem 1.875rem; }
  .card .card-text {
    margin-bottom: 1.5625rem; }
  .card a:hover {
    text-decoration: none; }
  .card-small {
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); }
    .card-small .card-header,
    .card-small .card-body,
    .card-small .card-footer {
      padding: 1rem 1rem; }

.card.light {
    background-color: rgba(255,255,255,1);
}

.card.dark {
    background-color: rgba(50,50,50,1);
}
@media (prefers-color-scheme: dark) {
    .card.dark {
        background-color: rgba(50,50,50,1);
    }
}

.card-body {
    position:relative;
  padding: 1.875rem; }
  .card-body > p:last-child {
    margin-bottom: 0; }

.card-title {
  font-weight: 500;
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -1.09375rem; }

.card-link {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
  .card-link + .card-link {
    margin-left: 1.875rem; }

.card-header {
  padding: 0.9rem 1.2rem;
  color: white;
  font-size:18px;
  font-weight:700;
  border-bottom: none;
  transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
  .card-header:first-child {
    border-radius: 0.625rem 0.625rem 0 0; }

.card-footer {
  padding: 1.09375rem 1.875rem;
  background-color: #fff;
  border-top: none; }
  .card-footer:last-child {
    border-radius: 0 0 0.625rem 0.625rem; }

.card-header-tabs {
  margin-bottom: -1rem;
  border-bottom: 0; }
  .card-header-tabs .nav-link,
  .card-header-tabs .nav-link:hover {
    border-bottom: transparent; }

.card-header-pills {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem; }
  .card-header-pills:hover {
    background: transparent; }

.card-img-overlay {
  padding: 1.875rem 2.1875rem;
  background: rgba(90, 97, 105, 0.5);
  border-radius: 0.625rem; }
  .card-img-overlay .card-title {
    color: #fff; }

.card-img {
  border-radius: 0.625rem; }

.card-img-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem; }

.card-img-bottom {
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem; }

.card-deck .card {
  margin-bottom: 0.9375rem; }

@media (min-width: 576px) {
  .card-deck {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; }
    .card-deck .card {
      margin-right: 0.9375rem;
      margin-left: 0.9375rem; } }

.card-group > .card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1); }
  .card-group > .card:last-child .card-body,
  .card-group > .card:last-child .card-footer {
    border-right: none; }

.card-group .card-body,
.card-group .card-footer {
  border-right: 1px solid rgba(175,175,175,0.5); }

@media (min-width: 576px) {
  .card-group {
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
    border-radius: 0.625rem; }
    .card-group > .card {
      box-shadow: none; }
      .card-group > .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .card-group > .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .card-group > .card:only-child {
        border-radius: 0.625rem; }
        .card-group > .card:only-child .card-img-top,
        .card-group > .card:only-child .card-header {
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem; }
        .card-group > .card:only-child .card-img-bottom,
        .card-group > .card:only-child .card-footer {
          border-bottom-right-radius: 0.625rem;
          border-bottom-left-radius: 0.625rem; }
      .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 2.1875rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; } }

.pagination {
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.875rem; }

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: none;
  margin: 0;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .page-link:hover, .page-link:focus {
    color: #0056b3;
    background-color: #f5f5f6;
    border-color: #dfe1e3; }

.page-item {
  box-shadow: 0 0.125rem 0.9375rem rgba(90, 97, 105, 0.1), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.15); }
  .page-item:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    overflow: hidden; }
  .page-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    overflow: hidden; }
    .page-item:last-child .page-link {
      border-right: none; }
  .page-item.active .page-link {
    color: #fff;
    background-color: white;
    border-color: white; }
  .page-item.disabled .page-link {
    color: #a8aeb4;
    background-color: #fff;
    border-color: #dfe1e3; }

.pagination-lg .page-link {
  padding: 0.9375rem 1.5625rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.6875rem;
  font-size: 0.6875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem; }

.badge {
  padding: 0.375rem 0.5rem;
  font-size: 75%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #fff;
  border-radius: 0.375rem; }

a.badge {
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }

.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem; }

.badge-squared {
  border-radius: 0; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-outline-primary {
  background: none;
  border: 1px solid #007bff;
  color: #007bff; }

.badge-secondary {
  color: #fff;
  background-color: #5A6169; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #42484e; }

.badge-outline-secondary {
  background: none;
  border: 1px solid #5A6169;
  color: #5A6169; }

.badge-success {
  color: #fff;
  background-color: #17c671; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #129857; }

.badge-outline-success {
  background: none;
  border: 1px solid #17c671;
  color: #17c671; }

.badge-info {
  color: #fff;
  background-color: #00b8d8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #008da5; }

.badge-outline-info {
  background: none;
  border: 1px solid #00b8d8;
  color: #00b8d8; }

.badge-warning {
  color: #212529;
  background-color: #ffb400; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #cc9000; }

.badge-outline-warning {
  background: none;
  border: 1px solid #ffb400;
  color: #ffb400; }

.badge-danger {
  color: #fff;
  background-color: #c4183c; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #97122e; }

.badge-outline-danger {
  background: none;
  border: 1px solid #c4183c;
  color: #c4183c; }

.badge-light {
  color: #212529;
  background-color: #FBFBFB; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e2e2e2; }

.badge-outline-light {
  background: none;
  border: 1px solid #FBFBFB;
  color: #FBFBFB;
  color: #212529; }

.badge-dark {
  color: #fff;
  background-color: #212529; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0a0c0d; }

.badge-outline-dark {
  background: none;
  border: 1px solid #212529;
  color: #212529; }

.jumbotron {
  padding: 38px 42px;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0.5rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0; }

.alert-link {
  font-weight: 500; }

.alert-dismissible .close {
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .alert-dismissible .close:hover {
    cursor: pointer; }

.alert-primary {
  color: #f5faff;
  background-color: #007bff; }
  .alert-primary .alert-link {
    color: #f5faff; }

.alert-secondary {
  color: #d9dcdf;
  background-color: #5A6169; }
  .alert-secondary .alert-link {
    color: #d9dcdf; }

.alert-success {
  color: #d7fae9;
  background-color: #17c671; }
  .alert-success .alert-link {
    color: #d7fae9; }

.alert-info {
  color: #cef8ff;
  background-color: #00b8d8; }
  .alert-info .alert-link {
    color: #cef8ff; }

.alert-warning {
  color: #fffcf5;
  background-color: #ffb400; }
  .alert-warning .alert-link {
    color: #fffcf5; }

.alert-danger {
  color: #fad7de;
  background-color: #c4183c; }
  .alert-danger .alert-link {
    color: #fad7de; }

.alert-light {
  color: white;
  background-color: #FBFBFB;
  color: #212529; }
  .alert-light .alert-link {
    color: white; }
  .alert-light .alert-link {
    color: #212529; }

.alert-dark {
  color: #959faa;
  background-color: #212529; }
  .alert-dark .alert-link {
    color: #959faa; }

.progress-wrapper {
  position: relative;
  color: #5A6169; }
  .progress-wrapper .progress-label {
    font-size: 0.7125rem; }
  .progress-wrapper .progress-value {
    position: absolute;
    top: 6px;
    right: 0;
    color: #5A6169; }

.progress {
  height: 0.625rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  background-color: #f5f5f6;
  margin-top: 6px;
  border-radius: 1.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(90, 97, 105, 0.15); }

.progress-sm {
  height: 0.3125rem; }

.progress-lg {
  height: 0.9375rem; }
  .progress-lg .progress-bar {
    height: 0.9375rem; }

.progress-bar {
  height: 0.625rem;
  line-height: 0.625rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.625rem 0.625rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.list-group-small .list-group-item {
  padding: 0.625rem 1rem;
  font-size: 0.7125rem; }

.list-group-item-action {
  color: #5A6169;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #5A6169;
    background-color: #f7f8f8; }
  .list-group-item-action:active {
    color: #5A6169;
    background-color: #eceeef; }

.list-group-item {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 300; }
  .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085; }
  a.list-group-item-primary:hover, a.list-group-item-primary:focus,
  button.list-group-item-primary:hover,
  button.list-group-item-primary:focus {
    color: #004085;
    background-color: #9fcdff; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #2f3237;
  background-color: #d1d3d5; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #2f3237; }
  a.list-group-item-secondary:hover, a.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus {
    color: #2f3237;
    background-color: #c4c6c9; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    background-color: #2f3237;
    border-color: #2f3237; }

.list-group-item-success {
  color: #0c673b;
  background-color: #beefd7; }

a.list-group-item-success,
button.list-group-item-success {
  color: #0c673b; }
  a.list-group-item-success:hover, a.list-group-item-success:focus,
  button.list-group-item-success:hover,
  button.list-group-item-success:focus {
    color: #0c673b;
    background-color: #aaeaca; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    background-color: #0c673b;
    border-color: #0c673b; }

.list-group-item-info {
  color: #006070;
  background-color: #b8ebf4; }

a.list-group-item-info,
button.list-group-item-info {
  color: #006070; }
  a.list-group-item-info:hover, a.list-group-item-info:focus,
  button.list-group-item-info:hover,
  button.list-group-item-info:focus {
    color: #006070;
    background-color: #a2e5f1; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    background-color: #006070;
    border-color: #006070; }

.list-group-item-warning {
  color: #855e00;
  background-color: #ffeab8; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #855e00; }
  a.list-group-item-warning:hover, a.list-group-item-warning:focus,
  button.list-group-item-warning:hover,
  button.list-group-item-warning:focus {
    color: #855e00;
    background-color: #ffe29f; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    background-color: #855e00;
    border-color: #855e00; }

.list-group-item-danger {
  color: #660c1f;
  background-color: #eebec8; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #660c1f; }
  a.list-group-item-danger:hover, a.list-group-item-danger:focus,
  button.list-group-item-danger:hover,
  button.list-group-item-danger:focus {
    color: #660c1f;
    background-color: #e9aab7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    background-color: #660c1f;
    border-color: #660c1f; }

.list-group-item-light {
  color: #838383;
  background-color: #fefefe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #838383; }
  a.list-group-item-light:hover, a.list-group-item-light:focus,
  button.list-group-item-light:hover,
  button.list-group-item-light:focus {
    color: #838383;
    background-color: #f1f1f1; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    background-color: #838383;
    border-color: #838383; }

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #111315; }
  a.list-group-item-dark:hover, a.list-group-item-dark:focus,
  button.list-group-item-dark:hover,
  button.list-group-item-dark:focus {
    color: #111315;
    background-color: #b4b5b6; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    background-color: #111315;
    border-color: #111315; }

.close {
  font-size: 1.5rem;
  font-weight: 500;
  color: #8c949d;
  text-shadow: none;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .close:hover, .close:focus {
    color: #8c949d; }

.modal {
  z-index: 1050; }

.modal-dialog {
  margin: 0.625rem;
  box-shadow:0px 10px 15px black

}
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out; }

.modal-dialog-centered {
  min-height: calc(100% - (0.625rem * 2)); }

.modal-content {
  background-color: rgba(245,245,245,1);
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.1), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.12), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1); }

.modal-backdrop {
  z-index: 1040;
  background-color: black; }
  .modal-backdrop.show {
    opacity: 0.4; }

.modal-header {
  padding: 0.9375rem 2.1875rem;
  border-bottom: 1px solid rgba(15,15,15,1); }

.modal-title {
  line-height: 1.5; }

.modal-body {
  padding: 1.875rem 2.1875rem; }

.modal-footer {
  padding: 0.9375rem 2.1875rem;
  border-top: 1px solid rgba(175,175,175,1); }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.875rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.875rem * 2)); }
  .modal-content {
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  z-index: 1070;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    width: 5px;
    height: 5px; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0; }
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-width: 5px 2.5px 0;
    border-top-color: #fff; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    width: 5px;
    height: 5px; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      border-width: 2.5px 5px 2.5px 0;
      border-right-color: #fff; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0; }
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-width: 0 2.5px 5px;
    border-bottom-color: #fff; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    width: 5px;
    height: 5px; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      border-width: 2.5px 0 2.5px 5px;
      border-left-color: #fff; }

.tooltip-inner {
  max-width: 200px;
  padding: 7px 13px;
  color: #5A6169;
  background-color: #fff;
  box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
  border-radius: 0.375rem; }

.popover {
  z-index: 1060;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem;
  background-color: rgba(255,255,255,1);
  border: none;
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 5px; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((5px + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 5px 5px 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    border-top-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 5px; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((5px + 1px) * -1);
    width: 5px;
    height: 10px;
    margin: 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 5px 5px 5px 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    border-right-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 5px; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((5px + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 5px 5px 5px; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    width: 10px;
    margin-left: -5px;
    border-bottom: 1px solid #f5f5f6; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 5px; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((5px + 1px) * -1);
    width: 5px;
    height: 10px;
    margin: 0.5rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 5px 0 5px 5px; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    border-left-color: rgba(0, 0, 0, 0.05); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 14px 20px;
  font-size: 1rem;
  color: #3D5170;
  line-height: 14px;
  background-color: #f5f5f6;
  border-bottom: 1px solid #e7e9ea;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px); }

.popover-body {
  padding: 15px 20px;
  color: #5A6169; }

.carousel {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1); }

.carousel-item {
  transition: transform 0.6s ease; }

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
  color: #fff;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px;
  height: 20px; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  margin-right: 15%;
  margin-left: 15%; }
  .carousel-indicators li {
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  right: 15%;
  left: 15%;
  color: #fff; }

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  background: #eceeef;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);
  margin: 35px 0; }
  .noUi-target :focus {
    outline: 0;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.65), 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2); }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #007bff;
  border-radius: 5px; }

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0; }

.noUi-handle {
  position: relative;
  z-index: 1;
  border: 1px solid #e7e9ea;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
  background: #fff;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .noUi-handle:hover {
    cursor: grab;
    cursor: -webkit-grab;
    cursor: -moz-grab; }
  .noUi-handle:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing; }
  .noUi-handle:after {
    left: 17px; }

.noUi-horizontal {
  height: 5px; }
  .noUi-horizontal .noUi-handle {
    left: -11.5px;
    top: -10px; }
  .noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 30px; }

.noUi-vertical {
  width: 5px; }
  .noUi-vertical .noUi-handle {
    left: -10px;
    top: -11.5px; }
    .noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
      width: 14px;
      height: 1px;
      left: 6px;
      top: 14px; }
    .noUi-vertical .noUi-handle:after {
      top: 17px; }
  .noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 30px; }

.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-active {
  transform: scale(1.1); }

[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

[disabled] .noUi-handle {
  background: #f2f3f4; }
  [disabled] .noUi-handle:focus {
    box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2); }

.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #a8aeb4;
  font-size: 12px; }

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #a8aeb4;
  font-size: 10px; }

.noUi-marker {
  position: absolute;
  background: #a8aeb4; }

.noUi-marker-sub {
  background: #a8aeb4; }

.noUi-marker-large {
  background: #a8aeb4; }

.noUi-pips-horizontal {
  padding: 10px 0;
  height: auto;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  transform: translate3d(-50%, 50%, 0); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 1px;
  height: 4px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 7px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  transform: translate3d(0, -50%, 0);
  padding-left: 25px; }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.375rem;
  border-radius: 0.375rem;
  background: #fff;
  color: #5A6169;
  box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
  font-size: 0.75rem;
  padding: 5px 10px; }

.slider-primary .noUi-connect {
  background: #007bff; }

.slider-secondary .noUi-connect {
  background: #5A6169; }

.slider-success .noUi-connect {
  background: #17c671; }

.slider-info .noUi-connect {
  background: #00b8d8; }

.slider-warning .noUi-connect {
  background: #ffb400; }

.slider-danger .noUi-connect {
  background: #c4183c; }

.slider-light .noUi-connect {
  background: #FBFBFB; }

.slider-dark .noUi-connect {
  background: #212529; }

.datepicker {
  border-radius: 0.625rem;
  direction: ltr; }
  .datepicker-inline {
    width: 220px; }
  .datepicker-rtl {
    direction: rtl; }
    .datepicker-rtl.dropdown-menu {
      left: auto; }
    .datepicker-rtl table tr td span {
      float: right; }
  .datepicker-dropdown {
    top: 0;
    left: 0;
    padding: 20px 22px; }
    .datepicker-dropdown:before, .datepicker-dropdown:after {
      content: '';
      display: inline-block;
      border-top: 0;
      position: absolute; }
    .datepicker-dropdown:before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #c3c7cc;
      border-bottom-color: rgba(0, 0, 0, 0.2); }
    .datepicker-dropdown:after {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff; }
    .datepicker-dropdown.datepicker-orient-left:before {
      left: 6px; }
    .datepicker-dropdown.datepicker-orient-left:after {
      left: 7px; }
    .datepicker-dropdown.datepicker-orient-right:before {
      right: 6px; }
    .datepicker-dropdown.datepicker-orient-right:after {
      right: 7px; }
    .datepicker-dropdown.datepicker-orient-bottom:before {
      top: -7px; }
    .datepicker-dropdown.datepicker-orient-bottom:after {
      top: -6px; }
    .datepicker-dropdown.datepicker-orient-top:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #c3c7cc; }
    .datepicker-dropdown.datepicker-orient-top:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff; }
  .datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    user-select: none; }
    .datepicker table tr td {
      border-radius: 50%; }
    .datepicker table tr th {
      border-radius: 0.375rem;
      font-weight: 500; }
    .datepicker table tr td, .datepicker table tr th {
      transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
      width: 36px;
      height: 36px;
      border: none;
      text-align: center; }
  .table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
    background-color: transparent; }
  .datepicker table tr td.old, .datepicker table tr td.new {
    color: #c3c7cc; }
  .datepicker table tr td.day:hover, .datepicker table tr td.focused {
    background: rgba(175,175,175,0.5) !important;
    cursor: pointer; }
  .datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
    background: none;
    color: rgba(255,255,255,0.1);
    cursor: default; }
  .datepicker table tr td.highlighted {
    border-radius: 0; }
    .datepicker table tr td.highlighted.focused {
      background: #007bff; }
    .datepicker table tr td.highlighted.disabled, .datepicker table tr td.highlighted.disabled:active {
      background: #007bff;
      color: rgba(0,0,0,0); }
  .datepicker table tr td.today {
    background: #e6f2ff; }
    .datepicker table tr td.today.focused {
      background: #f5f5f6; }
    .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:active {
      background: #f5f5f6;
      color: rgba(0,0,0,0); }
  .datepicker table tr td.range {
    background: #007bff;
    color: #fff;
    border-radius: 0; }
    .datepicker table tr td.range.focused {
      background: #0067d6; }
    .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:active, .datepicker table tr td.range.day.disabled:hover {
      background: #0062cc;
      color: rgba(0,0,0,0); }
  .datepicker table tr td.range.highlighted.focused {
    background: #cbd3da; }
  .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.highlighted.disabled:active {
    background: #e9ecef;
    color: rgba(0,0,0,0); }
  .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:active {
    background: #007bff;
    color: rgba(0,0,0,0); }
  .datepicker table tr td.day.range-start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .datepicker table tr td.day.range-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .datepicker table tr td.day.range-start.range-end {
    border-radius: 50%; }
  .datepicker table tr td.selected, .datepicker table tr td.selected.highlighted, .datepicker table tr td.selected:hover, .datepicker table tr td.selected.highlighted:hover, .datepicker table tr td.day.range:hover {
    background: #007bff;
    color: #fff; }
  .datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td.active:hover, .datepicker table tr td.active.highlighted:hover {
    background: #007bff;
    color: #fff; }
  .datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px; }
    .datepicker table tr td span:hover, .datepicker table tr td span.focused {
      background: rgba(65,65,65,1); }
    .datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
      background: none;
      color: rgba(0,0,0,0);
      cursor: default; }
    .datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }
    .datepicker table tr td span.old, .datepicker table tr td span.new {
      color: #868e96; }
  .datepicker .datepicker-switch {
    width: 145px; }
  .datepicker .datepicker-switch,
  .datepicker .prev,
  .datepicker .next,
  .datepicker tfoot tr th {
    cursor: pointer; }
    .datepicker .datepicker-switch:hover,
    .datepicker .prev:hover,
    .datepicker .next:hover,
    .datepicker tfoot tr th:hover {
      background: #e9ecef; }
  .datepicker .prev.disabled, .datepicker .next.disabled {
    visibility: hidden; }
  .datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle; }

.input-daterange input {
    height:33.5px !important;
  text-align: center; }

.bg-primary {
  background-color: #007bff !important; }
  .bg-primary.card .card-title,
  .bg-primary.card .card-body,
  .bg-primary.card .card-header,
  .bg-primary.card .card-footer {
    background-color: #0062cc !important; }
  .bg-primary.card .card-header,
  .bg-primary.card .card-footer {
    background: #0074f0; }

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #5A6169 !important; }
  .bg-secondary.card .card-title,
  .bg-secondary.card .card-body,
  .bg-secondary.card .card-header,
  .bg-secondary.card .card-footer {
    background-color: #42484e !important; }
  .bg-secondary.card .card-header,
  .bg-secondary.card .card-footer {
    background: #535961; }

a.bg-secondary:hover, a.bg-secondary:focus {
  background-color: #42484e !important; }

.bg-success {
  background-color: #17c671 !important; }
  .bg-success.card .card-title,
  .bg-success.card .card-body,
  .bg-success.card .card-header,
  .bg-success.card .card-footer {
    background-color: #129857 !important; }
  .bg-success.card .card-header,
  .bg-success.card .card-footer {
    background: #15b869; }

a.bg-success:hover, a.bg-success:focus {
  background-color: #129857 !important; }

.bg-info {
  background-color: #00b8d8 !important; }
  .bg-info.card .card-title,
  .bg-info.card .card-body,
  .bg-info.card .card-header,
  .bg-info.card .card-footer {
    background-color: #008da5 !important; }
  .bg-info.card .card-header,
  .bg-info.card .card-footer {
    background: #00abc9; }

a.bg-info:hover, a.bg-info:focus {
  background-color: #008da5 !important; }

.bg-warning {
  background-color: #ffb400 !important; }
  .bg-warning.card .card-title,
  .bg-warning.card .card-body,
  .bg-warning.card .card-header,
  .bg-warning.card .card-footer {
    background-color: #cc9000 !important; }
  .bg-warning.card .card-header,
  .bg-warning.card .card-footer {
    background: #f0a900; }

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #cc9000 !important; }

.bg-danger {
  background-color: #c4183c !important; }
  .bg-danger.card .card-title,
  .bg-danger.card .card-body,
  .bg-danger.card .card-header,
  .bg-danger.card .card-footer {
    background-color: #97122e !important; }
  .bg-danger.card .card-header,
  .bg-danger.card .card-footer {
    background: #b61638; }

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #97122e !important; }

.bg-light {
  background-color: #FBFBFB !important; }
  .bg-light.card .card-title,
  .bg-light.card .card-body,
  .bg-light.card .card-header,
  .bg-light.card .card-footer {
    background-color: #e2e2e2 !important; }
  .bg-light.card .card-header,
  .bg-light.card .card-footer {
    background: #f3f3f3; }

a.bg-light:hover, a.bg-light:focus {
  background-color: #e2e2e2 !important; }

.bg-dark {
  background-color: #212529 !important; }
  .bg-dark.card .card-title,
  .bg-dark.card .card-body,
  .bg-dark.card .card-header,
  .bg-dark.card .card-footer {
    background-color: #0a0c0d !important; }
  .bg-dark.card .card-header,
  .bg-dark.card .card-footer {
    background: #1a1d21; }

a.bg-dark:hover, a.bg-dark:focus {
  background-color: #0a0c0d !important; }

.border {
  border: 1px solid rgba(25,25,25,1) !important; }

.border-top {
  border-top: 1px solid rgba(25,25,25,1) !important; }

.border-right {
  border-right: 1px solid rgba(25,25,25,1) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(25,25,25,1) !important; }

.border-left {
  border-left: 1px solid rgba(25,25,25,1) !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #5A6169 !important; }

.border-success {
  border-color: #17c671 !important; }

.border-info {
  border-color: #00b8d8 !important; }

.border-warning {
  border-color: #ffb400 !important; }

.border-danger {
  border-color: #c4183c !important; }

.border-light {
  border-color: #FBFBFB !important; }

.border-dark {
  border-color: #212529 !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.font-weight-normal {
  font-weight: 300; }

.font-weight-bold {
  font-weight: 500; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #5A6169 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #42484e !important; }

.text-success {
  color: #17c671 !important; }

a.text-success:hover, a.text-success:focus {
  color: #129857 !important; }

.text-info {
  color: #00b8d8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #008da5 !important; }

.text-warning {
  color: #ffb400 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #cc9000 !important; }

.text-danger {
  color: #c4183c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #97122e !important; }

.text-light {
  color: #FBFBFB !important; }

a.text-light:hover, a.text-light:focus {
  color: #e2e2e2 !important; }

.text-dark {
  color: #212529 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0a0c0d !important; }

a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important; }

.text-black {
  color: #000; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-muted {
  color: #868e96 !important; }

.with-shadows {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1); }

:root {
  --salmon: #FF4169;
  --royal-blue: #674EEC;
  --java: #1ADBA2; }

html {
  font-size: 16px;
  font-weight: 500; }
  @media (min-width: 1200px) {
    html {
      font-size: 18px; } }
  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    html {
      font-size: 16px;
      font-weight: 400; } }

body {
  font-size: 15px;
  font-weight: 500; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    body {
      font-size: 13px;
      font-weight: 400; } }

a {
  color: #007bff; }
  a:hover {
    color: #0056b3; }

label {
  font-weight: 400; }

.datepicker {
  font-size: .75rem;
  padding: 0.625rem; }
  .datepicker table tr td,
  .datepicker table tr th {
    width: 1.875rem;
    height: 1.875rem; }

.btn-salmon {
  color: #fff;
  border-color: #FF4169;
  background-color: #FF4169;
  box-shadow: none; }
  .btn-salmon:hover {
    color: #fff;
    background-color: #ff2855;
    border-color: #ff2855;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 65, 105, 0.25); }
  .btn-salmon:focus, .btn-salmon.focus {
    box-shadow: 0 0 0 3px rgba(255, 65, 105, 0.15), 0 3px 15px rgba(255, 65, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-salmon.disabled, .btn-salmon:disabled {
    background-color: #FF4169;
    border-color: #FF4169;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-salmon:not(:disabled):not(.disabled):active, .btn-salmon:not(:disabled):not(.disabled).active,
  .show > .btn-salmon.dropdown-toggle {
    color: #fff;
    background-color: #ff2855;
    border-color: #ff0e41;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-royal-blue {
  color: #fff;
  border-color: #674EEC;
  background-color: #674EEC;
  box-shadow: none; }
  .btn-royal-blue:hover {
    color: #fff;
    background-color: #5337ea;
    border-color: #5337ea;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(103, 78, 236, 0.25); }
  .btn-royal-blue:focus, .btn-royal-blue.focus {
    box-shadow: 0 0 0 3px rgba(103, 78, 236, 0.15), 0 3px 15px rgba(103, 78, 236, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-royal-blue.disabled, .btn-royal-blue:disabled {
    background-color: #674EEC;
    border-color: #674EEC;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-royal-blue:not(:disabled):not(.disabled):active, .btn-royal-blue:not(:disabled):not(.disabled).active,
  .show > .btn-royal-blue.dropdown-toggle {
    color: #fff;
    background-color: #5337ea;
    border-color: #3f20e7;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-java {
  color: #212529;
  border-color: #1ADBA2;
  background-color: #1ADBA2;
  box-shadow: none; }
  .btn-java:hover {
    color: #fff;
    background-color: #17c491;
    border-color: #17c491;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(26, 219, 162, 0.25); }
  .btn-java:focus, .btn-java.focus {
    box-shadow: 0 0 0 3px rgba(26, 219, 162, 0.15), 0 3px 15px rgba(26, 219, 162, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-java.disabled, .btn-java:disabled {
    background-color: #1ADBA2;
    border-color: #1ADBA2;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-java:not(:disabled):not(.disabled):active, .btn-java:not(:disabled):not(.disabled).active,
  .show > .btn-java.dropdown-toggle {
    color: #fff;
    background-color: #17c491;
    border-color: #15ad80;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-accent {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: none; }
  .btn-accent:hover {
    color: #fff;
    background-color: #006fe6;
    border-color: #006fe6;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25); }
  .btn-accent:focus, .btn-accent.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); }
  .btn-accent.disabled, .btn-accent:disabled {
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: none;
    cursor: not-allowed; }
  .btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
  .show > .btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #006fe6;
    border-color: #0062cc;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-salmon {
  background-color: transparent;
  background-image: none;
  border-color: #FF4169;
  color: #FF4169; }
  .btn-outline-salmon:hover {
    color: #fff;
    background-color: #FF4169;
    border-color: #FF4169;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 65, 105, 0.25); }
  .btn-outline-salmon:focus, .btn-outline-salmon.focus {
    box-shadow: 0 0 0 3px rgba(255, 65, 105, 0.15), 0 3px 15px rgba(255, 65, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-salmon.disabled, .btn-outline-salmon:disabled {
    color: #FF4169;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-salmon:not(:disabled):not(.disabled):active, .btn-outline-salmon:not(:disabled):not(.disabled).active,
  .show > .btn-outline-salmon.dropdown-toggle {
    color: #fff;
    background-color: #FF4169;
    border-color: #FF4169; }
    .btn-outline-salmon:not(:disabled):not(.disabled):active:focus, .btn-outline-salmon:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-salmon.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-royal-blue {
  background-color: transparent;
  background-image: none;
  border-color: #674EEC;
  color: #674EEC; }
  .btn-outline-royal-blue:hover {
    color: #fff;
    background-color: #674EEC;
    border-color: #674EEC;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(103, 78, 236, 0.25); }
  .btn-outline-royal-blue:focus, .btn-outline-royal-blue.focus {
    box-shadow: 0 0 0 3px rgba(103, 78, 236, 0.15), 0 3px 15px rgba(103, 78, 236, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-royal-blue.disabled, .btn-outline-royal-blue:disabled {
    color: #674EEC;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-royal-blue:not(:disabled):not(.disabled):active, .btn-outline-royal-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-royal-blue.dropdown-toggle {
    color: #fff;
    background-color: #674EEC;
    border-color: #674EEC; }
    .btn-outline-royal-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-royal-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-royal-blue.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-java {
  background-color: transparent;
  background-image: none;
  border-color: #1ADBA2;
  color: #1ADBA2; }
  .btn-outline-java:hover {
    color: #212529;
    background-color: #1ADBA2;
    border-color: #1ADBA2;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(26, 219, 162, 0.25); }
  .btn-outline-java:focus, .btn-outline-java.focus {
    box-shadow: 0 0 0 3px rgba(26, 219, 162, 0.15), 0 3px 15px rgba(26, 219, 162, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-java.disabled, .btn-outline-java:disabled {
    color: #1ADBA2;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-java:not(:disabled):not(.disabled):active, .btn-outline-java:not(:disabled):not(.disabled).active,
  .show > .btn-outline-java.dropdown-toggle {
    color: #212529;
    background-color: #1ADBA2;
    border-color: #1ADBA2; }
    .btn-outline-java:not(:disabled):not(.disabled):active:focus, .btn-outline-java:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-java.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-outline-accent {
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
  color: #007bff; }
  .btn-outline-accent:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25); }
  .btn-outline-accent:focus, .btn-outline-accent.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important; }
  .btn-outline-accent.disabled, .btn-outline-accent:disabled {
    color: #007bff;
    background-color: transparent;
    box-shadow: none; }
  .btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important; }

.btn-group-sm i {
  transform: scale(1.3); }

.navbar-light .navbar-brand {
  font-weight: 500;
  color: #3D5170; }

.nav-link {
  font-size: 0.7125rem;
  font-weight: 400; }
  .nav-link-icon {
    color: rgba(225,225,225,1); }
    .nav-link-icon:hover, .nav-link-icon:focus {
      color: rgba(225,225,225,1); }
    .nav-link-icon__wrapper {
      position: relative; }
    .nav-link-icon i {
      font-size: 1.25rem; }
      .nav-link-icon i.material-icons {
        font-size: 1.5625rem; }

.badge-salmon {
  color: #fff;
  background-color: #FF4169; }
  .badge-salmon[href]:hover, .badge-salmon[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ff0e41; }

.badge-outline-salmon {
  background: none;
  border: 1px solid #FF4169;
  color: #FF4169; }

.badge-royal-blue {
  color: #fff;
  background-color: #674EEC; }
  .badge-royal-blue[href]:hover, .badge-royal-blue[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3f20e7; }

.badge-outline-royal-blue {
  background: none;
  border: 1px solid #674EEC;
  color: #674EEC; }

.badge-java {
  color: #212529;
  background-color: #1ADBA2; }
  .badge-java[href]:hover, .badge-java[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #15ad80; }

.badge-outline-java {
  background: none;
  border: 1px solid #1ADBA2;
  color: #1ADBA2; }

.badge-accent {
  color: #fff;
  background-color: #007bff; }
  .badge-accent[href]:hover, .badge-accent[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-outline-accent {
  background: none;
  border: 1px solid #007bff;
  color: #007bff; }

.alert-salmon {
  color: white;
  background-color: #FF4169; }
  .alert-salmon .alert-link {
    color: white; }

.alert-royal-blue {
  color: white;
  background-color: #674EEC; }
  .alert-royal-blue .alert-link {
    color: white; }

.alert-java {
  color: #edfdf8;
  background-color: #1ADBA2; }
  .alert-java .alert-link {
    color: #edfdf8; }

.alert-accent {
  color: #f5faff;
  background-color: #007bff; }
  .alert-accent .alert-link {
    color: #f5faff; }

.alert-dismissible .close {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.input-group {
  min-width: 7.5rem; }

.input-group-text {
  font-size: 0.7125rem; }

.input-group.input-group-seamless > .form-control:not(:first-child),
.input-group.input-group-seamless > .custom-select:not(:first-child) {
  padding-left: 1.875rem; }

.input-group-text i {
  transform: scale(1.1); }
  .input-group-text i.fa {
    font-size: 0.75rem; }
  .input-group-text i.material-icons {
    top: 0;
    font-size: 0.7125rem; }

.custom-select-sm {
  font-size: 0.6875rem;
  border-radius: 0.25rem; }

.custom-select-lg {
  border-radius: 0.25rem; }

.custom-control {
  line-height: 1.5rem; }

.sc-legend-container {
  padding: 0;
  margin: 0;
  padding: 10px 30px 7px 30px; }
  .sc-legend-container li {
    display: inline-block;
    margin-right: .75rem;
    font-size: 12px;
    color: #818EA3; }

.sc-legend {
  position: relative;
  padding-left: 25px; }
  .sc-legend__label {
    position: absolute;
    width: 20px;
    border-radius: 10px;
    height: 2px;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }

div[class^="sc-tooltip-"] {
  background: #fff;
  border-radius: 7px;
  font-size: 11px;
  opacity: 1;
  position: absolute;
  transition: all .1s ease;
  transform: translate(-50%, 0);
  padding: 5px 10px;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  pointer-events: none;
  min-width: 120px;
  white-space: nowrap; }
  div[class^="sc-tooltip-"] thead {
    text-align: center; }
    div[class^="sc-tooltip-"] thead tr th {
      font-weight: 500; }

.sc-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px; }

.thumbnail {
  border: 1px solid #D4D4D4; }

i.material-icons {
  top: 0; }

.dropdown .fa,
.dropdown .material-icons {
  color: #c3c7cc;
  margin-right: 0.25rem; }

.dropdown .dropdown-item.light:hover {
  background-color: rgba(245,245,245,1);
  color:white;
}
.dropdown .dropdown-item.dark:hover {
  background-color: rgba(85,85,85,1);
  color:white;
}

.card-header {
  position: relative; }
  .card-header h1, .card-header h2, .card-header h3, .card-header h4, .card-header h5, .card-header h6 {
    font-weight: 500; }
  .card-header .block-handle {
    position: absolute;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxkZWZzPiAgICAgICAgPHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBpZD0iYSIvPiAgICA8L2RlZnM+ICAgIDxjbGlwUGF0aCBpZD0iYiI+ICAgICAgICA8dXNlIG92ZXJmbG93PSJ2aXNpYmxlIiB4bGluazpocmVmPSIjYSIvPiAgICA8L2NsaXBQYXRoPiAgICA8cGF0aCBjbGlwLXBhdGg9InVybCgjYikiIGQ9Ik0yMCA5SDR2MmgxNlY5ek00IDE1aDE2di0ySDR2MnoiLz48L3N2Zz4=);
    background-position: center center;
    background-size: 95%;
    width: 1.125rem;
    height: 0.5rem;
    top: 50%;
    right: 0.9375rem;
    transform: translateY(-50%);
    opacity: .15; }
    .card-header .block-handle:hover {
      cursor: -webkit-grab; }

.card .view-report {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.75rem; }
  .card .view-report a {
    color: #3D5170; }
    .card .view-report a:hover {
      color: #007bff; }

.bg-salmon {
  background-color: #FF4169 !important; }
  .bg-salmon.card .card-title,
  .bg-salmon.card .card-body,
  .bg-salmon.card .card-header,
  .bg-salmon.card .card-footer {
    background-color: #ff0e41 !important; }
  .bg-salmon.card .card-header,
  .bg-salmon.card .card-footer {
    background: #ff325d; }

a.bg-salmon:hover, a.bg-salmon:focus {
  background-color: #ff0e41 !important; }

.bg-royal-blue {
  background-color: #674EEC !important; }
  .bg-royal-blue.card .card-title,
  .bg-royal-blue.card .card-body,
  .bg-royal-blue.card .card-header,
  .bg-royal-blue.card .card-footer {
    background-color: #3f20e7 !important; }
  .bg-royal-blue.card .card-header,
  .bg-royal-blue.card .card-footer {
    background: #5b40eb; }

a.bg-royal-blue:hover, a.bg-royal-blue:focus {
  background-color: #3f20e7 !important; }

.bg-java {
  background-color: #1ADBA2 !important; }
  .bg-java.card .card-title,
  .bg-java.card .card-body,
  .bg-java.card .card-header,
  .bg-java.card .card-footer {
    background-color: #15ad80 !important; }
  .bg-java.card .card-header,
  .bg-java.card .card-footer {
    background: #18cd98; }

a.bg-java:hover, a.bg-java:focus {
  background-color: #15ad80 !important; }

.text-salmon {
  color: #FF4169 !important; }

a.text-salmon:hover, a.text-salmon:focus {
  color: #ff0e41 !important; }

.text-royal-blue {
  color: #674EEC !important; }

a.text-royal-blue:hover, a.text-royal-blue:focus {
  color: #3f20e7 !important; }

.text-java {
  color: #1ADBA2 !important; }

a.text-java:hover, a.text-java:focus {
  color: #15ad80 !important; }

.text-fiord-blue {
  color: #3D5170 !important; }

a.text-fiord-blue:hover, a.text-fiord-blue:focus {
  color: #2b394f !important; }

.text-shuttle-gray {
  color: #5A6169 !important; }

a.text-shuttle-gray:hover, a.text-shuttle-gray:focus {
  color: #42484e !important; }

.text-reagent-gray {
  color: #818EA3 !important; }

a.text-reagent-gray:hover, a.text-reagent-gray:focus {
  color: #66748b !important; }

.text-mischka {
  color: #CACEDB !important; }

a.text-mischka:hover, a.text-mischka:focus {
  color: #acb2c6 !important; }

.text-athens-gray {
  color: #E9ECEF !important; }

a.text-athens-gray:hover, a.text-athens-gray:focus {
  color: #cbd3da !important; }

.text-accent {
  color: #007bff !important; }

a.text-accent:hover, a.text-accent:focus {
  color: #0062cc !important; }

.text-light {
  color: #818EA3 !important; }

.text-semibold {
  font-weight: 400; }

.border {
  border: 1px solid rgba(15,15,15,1) !important; }

.border-top {
  border-top: 1px solid rgba(15,15,15,1) !important; }

.border-right {
  border-right: 1px solid rgba(15,15,15,1) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(15,15,15,1) !important; }

.border-left {
  border-left: 1px solid rgba(15,15,15,1) !important; }

.rounded {
  border-radius: 5px !important; }

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important; }

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; }

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.border-salmon {
  border-color: #FF4169 !important; }

.border-royal-blue {
  border-color: #674EEC !important; }

.border-java {
  border-color: #1ADBA2 !important; }

.border-accent {
  border-color: #007bff !important; }

.dataTables_wrapper {
  background: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); }
  .dataTables_wrapper::after {
    display: block;
    clear: both;
    content: ""; }
  .dataTables_wrapper table.dataTable {
    border-top: 1px solid #e1e5eb;
    font-size: 0.7125rem;
    display: table !important;
    width: 100% !important; }
    @media (max-width: 991.98px) {
      .dataTables_wrapper table.dataTable {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 0; } }
    .dataTables_wrapper table.dataTable thead tr:last-child th {
      padding-right: 1.7rem; }
    .dataTables_wrapper table.dataTable thead th {
      text-align: center;
      background-color: #FBFBFB;
      border: 1px solid #e1e5eb;
      font-weight: 400;
      padding: 0.75rem 1.0625rem; }
      .dataTables_wrapper table.dataTable thead th:focus {
        outline: 0; }
      .dataTables_wrapper table.dataTable thead th:first-child {
        border-left: none; }
      .dataTables_wrapper table.dataTable thead th:last-child {
        border-right: none; }
      .dataTables_wrapper table.dataTable thead th.hide-sort-icons:after {
        display: none; }
      .dataTables_wrapper table.dataTable thead th.sorting, .dataTables_wrapper table.dataTable thead th.sorting_asc, .dataTables_wrapper table.dataTable thead th.sorting_desc {
        position: relative; }
        .dataTables_wrapper table.dataTable thead th.sorting:after, .dataTables_wrapper table.dataTable thead th.sorting_asc:after, .dataTables_wrapper table.dataTable thead th.sorting_desc:after {
          content: '';
          position: absolute;
          background-position: center;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          opacity: .2; }
      .dataTables_wrapper table.dataTable thead th.sorting:after {
        width: 10px;
        height: 13px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjEzcHgiIHZpZXdCb3g9IjAgMCAxMCAxMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY19hcnJvd19kcm9wX2Rvd25fYmxhY2tfMjRweDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImljX2Fycm93X2Ryb3BfZG93bl9ibGFja18yNHB4LSgxKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTcuMDAwMDAwLCAtMi4wMDAwMDApIiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxnIGlkPSJpY19hcnJvd19kcm9wX2Rvd25fYmxhY2tfMjRweCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIuMDAwMDAwLCA4LjUwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEyLjAwMDAwMCwgLTguNTAwMDAwKSB0cmFuc2xhdGUoNy4wMDAwMDAsIDIuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJTaGFwZSIgcG9pbnRzPSIxLjQyMTA4NTQ3ZS0xNCA4IDUgMTMgMTAgOCI+PC9wb2x5Z29uPiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iU2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuMDAwMDAwLCAzLjMzMzg5Nykgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTUuMDAwMDAwLCAtMy4zMzM4OTcpICIgcG9pbnRzPSIxLjQyMTA4NTQ3ZS0xNCAwLjgzMzg5NjkyMiA1IDUuODMzODk2OTIgMTAgMC44MzM4OTY5MjIiPjwvcG9seWdvbj4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
        background-size: 90%; }
      .dataTables_wrapper table.dataTable thead th.sorting_asc:after {
        width: 10px;
        height: 6px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==); }
      .dataTables_wrapper table.dataTable thead th.sorting_desc:after {
        width: 10px;
        height: 6px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==); }
    .dataTables_wrapper table.dataTable tbody td {
      padding: 0.5275rem 1.0625rem;
      border: 1px solid #e1e5eb;
      text-align: center; }
      .dataTables_wrapper table.dataTable tbody td:first-child {
        border-left: none; }
      .dataTables_wrapper table.dataTable tbody td::last-child {
        border-right: none; }
      .dataTables_wrapper table.dataTable tbody td.dataTables_empty {
        padding: 0.7125rem 1.0625rem; }
    .dataTables_wrapper table.dataTable td.child {
      padding: 0 !important; }
      .dataTables_wrapper table.dataTable td.child ul {
        width: 100%; }
        .dataTables_wrapper table.dataTable td.child ul li {
          padding: 1rem !important;
          display: flex; }
          .dataTables_wrapper table.dataTable td.child ul li::after {
            display: block;
            clear: both;
            content: ""; }
      .dataTables_wrapper table.dataTable td.child .dtr-title {
        float: left;
        text-align: left;
        margin: auto 0; }
      .dataTables_wrapper table.dataTable td.child .dtr-data {
        margin-left: auto; }
    .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role="row"] > td:first-child:before,
    .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role="row"] > th:first-child:before {
      box-shadow: none;
      text-align: center;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 22px;
      font-size: 1rem;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); }

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
  width: 50%;
  float: left;
  font-size: 13px;
  padding: 15px 20px;
  color: #818EA3; }
  @media (max-width: 575.98px) {
    .dataTables_length,
    .dataTables_filter,
    .dataTables_info,
    .dataTables_paginate {
      width: 100%;
      max-width: 100% !important; } }
  .dataTables_length label,
  .dataTables_filter label,
  .dataTables_info label,
  .dataTables_paginate label {
    margin: 0; }

@media (max-width: 575.98px) {
  .dataTables_length label {
    display: table;
    margin: 0 auto; }
  .dataTables_filter {
    width: 100%; }
    .dataTables_filter label, .dataTables_filter input {
      margin: 0 !important;
      width: 100%;
      background-size: 5% !important; } }

.dataTables_info,
.dataTables_paginate {
  padding: 20px;
  background: #FBFBFB;
  border-radius: 0 0 0.375rem 0.375rem; }
  @media (max-width: 767.98px) {
    .dataTables_info,
    .dataTables_paginate {
      padding: 14px;
      width: 100%;
      text-align: center !important; } }

@media (max-width: 767.98px) {
  .dataTables_info {
    border-bottom: 1px solid #e1e5eb;
    border-radius: 0; } }

.dataTables_length select {
  border: 1px solid #e1e5eb;
  font-size: 0.625rem;
  color: #818EA3;
  height: 1.5625rem;
  background-color: #fff;
  border-radius: 0.375rem;
  margin: 0 0.1875rem; }

.dataTables_paginate {
  text-align: right; }
  .dataTables_paginate .paginate_button,
  .dataTables_paginate .ellipsis {
    background: #fff;
    padding: 0.4286rem 0.875rem;
    border: 1px solid #e1e5eb; }
    @media (max-width: 767.98px) {
      .dataTables_paginate .paginate_button,
      .dataTables_paginate .ellipsis {
        font-size: 0.625rem;
        padding: 0.4286rem 0.6rem; } }
  .dataTables_paginate .paginate_button {
    border-radius: 0.25rem;
    color: #3D5170;
    will-change: background-color, color, border-color;
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out; }
    .dataTables_paginate .paginate_button:hover {
      cursor: pointer;
      text-decoration: none; }
    .dataTables_paginate .paginate_button:not(.disabled):hover, .dataTables_paginate .paginate_button.current {
      background: #007bff;
      color: #fff;
      border-color: #007bff; }
    .dataTables_paginate .paginate_button:focus {
      outline: 0; }
    .dataTables_paginate .paginate_button.disabled {
      background-color: #f2f4f5; }
      .dataTables_paginate .paginate_button.disabled:hover {
        cursor: not-allowed; }
    .dataTables_paginate .paginate_button.previous {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .dataTables_paginate .paginate_button.next {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .dataTables_paginate .ellipsis {
    border-left: none; }
  .dataTables_paginate span > .paginate_button {
    border-radius: 0;
    border-left: 0; }
    .dataTables_paginate span > .paginate_button:hover {
      border-color: #007bff; }
    .dataTables_paginate span > .paginate_button:last-child {
      border-right: 0; }

.dataTables_filter {
  padding: 0.625rem; }
  @media (max-width: 767.98px) {
    .dataTables_filter {
      max-width: 200px;
      float: right; } }
  .dataTables_filter label {
    float: right;
    font-size: 0; }
  .dataTables_filter input {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #e1e5eb;
    font-weight: 300;
    border-radius: 0.25rem;
    margin-left: 0.3125rem;
    padding: 0.5275rem 0.625rem 0.5275rem 1.75rem;
    min-width: 11.25rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjQ0FDRURCIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0MxNS40MSAxMi41OSAxNiAxMS4xMSAxNiA5LjUgMTYgNS45MSAxMy4wOSAzIDkuNSAzUzMgNS45MSAzIDkuNSA1LjkxIDE2IDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNSAxNCA3LjAxIDE0IDkuNSAxMS45OSAxNCA5LjUgMTR6Ii8+ICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
    background-position: left 7px center;
    background-repeat: no-repeat;
    background-size: 10%;
    will-change: border-color, box-shadow;
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
    .dataTables_filter input:hover {
      border-color: #b3bdcc; }
    .dataTables_filter input:focus {
      color: #495057;
      background-color: #fff;
      border-color: #007bff;
      box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }
      .dataTables_filter input:focus:hover {
        border-color: #007bff; }
    .dataTables_filter input:focus {
      outline: 0; }
    .dataTables_filter input::placeholder {
      color: #868e96; }

.dropzone {
  color: #818EA3; }
  .dropzone.dz-drag-hover .dz-default {
    background: #fff;
    border-color: #cbd3da; }
  .dropzone .dz-default {
    text-align: center;
    border: 1px dashed #dadfe4;
    padding: 0.75rem;
    position: relative;
    border-radius: 0.375rem;
    will-change: border-color, background;
    transition: border-color 250ms ease-in-out, background 250ms ease-in-out; }
    .dropzone .dz-default:hover {
      cursor: pointer; }
    .dropzone .dz-default + .dz-preview {
      margin-top: 0.75rem; }
  .dropzone .dz-preview {
    width: 100%;
    border: 1px solid #E9ECEF;
    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0 1px 1px #E9ECEF;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between; }
    .dropzone .dz-preview + .dz-preview {
      margin-top: 0.75rem; }
    .dropzone .dz-preview .dz-image {
      max-width: 50px;
      border-radius: 0.375rem;
      overflow: hidden;
      margin-right: 20px; }
      .dropzone .dz-preview .dz-image img {
        width: 100%; }
    .dropzone .dz-preview .dz-details,
    .dropzone .dz-preview .dz-error-message {
      justify-content: center;
      display: flex;
      flex-direction: column-reverse; }
    .dropzone .dz-preview .dz-details {
      margin-right: 3rem;
      flex: 2; }
    .dropzone .dz-preview .dz-filename {
      font-weight: 500;
      color: #3D5170;
      font-size: 0.875rem; }
    .dropzone .dz-preview .dz-size {
      font-size: 80%;
      color: #818EA3; }
    .dropzone .dz-preview .dz-error-message span {
      background-color: #c4183c;
      color: #fff;
      background-color: #c4183c;
      color: #fff;
      padding: 5px 12px;
      border-radius: 5px;
      font-size: 12px; }
    .dropzone .dz-preview.dz-error .dz-success-mark {
      display: none; }
    .dropzone .dz-preview .dz-success-mark svg,
    .dropzone .dz-preview .dz-error-mark svg {
      display: none; }

.bootstrap-tagsinput {
  padding: 0.5275rem 0.375rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .bootstrap-tagsinput.focus {
    color: #495057;
    background-color: #fff;
    border-color: #007bff;
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06); }
  .bootstrap-tagsinput > input {
    border: none;
    padding: 0; }
    .bootstrap-tagsinput > input:focus {
      outline: 0; }
  .bootstrap-tagsinput .tag {
    display: inline-block;
    background: #FBFBFB;
    padding: 0.125rem 1.375rem 0.125rem 0.625rem;
    margin-bottom: 0.3125rem;
    border-radius: 1.25rem;
    font-size: 0.6875rem;
    position: relative;
    text-transform: uppercase;
    border: 1px solid #e1e5eb;
    color: #818EA3; }
    .bootstrap-tagsinput .tag > span {
      position: absolute;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
      right: 0.5rem;
      top: 50%;
      width: 0.625rem;
      height: 0.625rem;
      background-repeat: no-repeat;
      background-position: center center;
      transform: translateY(-50%);
      background-size: 100%;
      opacity: .5; }
      .bootstrap-tagsinput .tag > span:hover {
        cursor: pointer; }

.main-navbar {
  height: 3.75rem; }
  .main-navbar > * {
    display: flex; }
  .main-navbar .nav-link {
    min-width: 3.75rem; }
  .main-navbar .notifications {
    position: relative; }
    @media (max-width: 767.98px) {
      .main-navbar .notifications {
        position: static; }
        .main-navbar .notifications .dropdown-menu {
          min-width: 100% !important;
          border-left: none; } }
    .main-navbar .notifications .badge {
      position: absolute;
      padding: 0.25rem 0.375rem;
      font-size: 0.5rem;
      left: 50%;
      top: 50%; }
    .main-navbar .notifications .dropdown-menu {
      padding: 0;
      min-width: 25rem;
      border-right: 0;
      left: auto; }
      @media (max-width: 767.98px) {
        .main-navbar .notifications .dropdown-menu {
          left: 0;
          right: 0; } }
      .main-navbar .notifications .dropdown-menu .dropdown-item {
        white-space: normal;
        display: flex;
        flex-flow: row;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        border-bottom: 1px solid rgba(45,45,45,1); }
        .main-navbar .notifications .dropdown-menu .dropdown-item:last-child {
          border-bottom: 0; }
      .main-navbar .notifications .dropdown-menu .notification__icon-wrapper {
        display: flex;
        padding: 0 5px; }
      .main-navbar .notifications .dropdown-menu .notification__icon {
        box-shadow: 0 0 0 1px rgba(0,0,0,0.25), inset 0 0 3px rgba(0, 0, 0, 0.2);
        width: 2.1875rem;
        height: 2.1875rem;
        line-height: 0;
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 50%; }
        .main-navbar .notifications .dropdown-menu .notification__icon i {
          color: #818EA3;
          line-height: 2.0625rem;
          font-size: 1.0625rem;
          margin: 0; }
      .main-navbar .notifications .dropdown-menu .notification__content {
        padding: 0 0.625rem; }
        .main-navbar .notifications .dropdown-menu .notification__content p {
          margin: 0;
          line-height: 1.5;
          font-size: 0.75rem; }
      .main-navbar .notifications .dropdown-menu .notification__category {
        font-size: 0.5625rem;
        color: #818EA3;
        letter-spacing: 0.0938rem;
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 500; }
      .main-navbar .notifications .dropdown-menu .notification__all {
        display: block;
        font-weight: 500;
        font-size: 11px;
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem; }
  .main-navbar .main-navbar__icon-actions .nav-link {
    border:none; }
  .main-navbar .user-avatar {
    max-width: 2.5rem; }
  .main-navbar .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none; }
  .main-navbar .nav-link-icon i {
    line-height: 2.5rem; }

.main-content > .main-navbar {
  box-shadow: none }

  .main-navbar.light {
      background-color: white;
  }

  .main-navbar.dark {
      background-color: rgba(45,45,45,1);
  }

.main-sidebar.light {
    background-color: white;
}

.main-sidebar.dark {
    background-color: rgba(25,25,25,1);
}

.main-sidebar {
  top: 0;
  position: fixed;
  height: calc(100vh);
  z-index: 1070;
  will-change: transform;
  transition: transform 200ms ease-in-out;
  box-shadow: none }
  @media (max-width: 767.98px) {
    .main-sidebar {
      box-shadow: none; 
    } 
  }
  .main-sidebar.open {
    transform: translateX(0);
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12), 0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.5275rem 2.1875rem rgba(165, 182, 201, 0.1); }
  .main-sidebar .toggle-sidebar {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1.25rem;
    font-size: 1.25rem;
    border: 0px solid rgba(45,45,45,1); }
    .main-sidebar .toggle-sidebar:hover {
      cursor: pointer; }
  .main-sidebar .navbar-brand {
    overflow: hidden;
    height: 3.75rem;
    font-size: 1rem; }
    @media (max-width: 991.98px) {
      .main-sidebar .navbar-brand {
        font-size: 90%; } }
  .main-sidebar .nav-wrapper {
    overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
    overflow-x: hidden;
/*height: calc(100vh - 3.75rem - 1px);*/
    height:100vh;
    padding-bottom:200px
 }

  .main-sidebar .nav .nav-item,
  .main-sidebar .nav .nav-link {
    white-space: nowrap;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    will-change: background-color, box-shadow, color;
    transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease;
    font-size: 0.85rem; }

    .main-sidebar .nav .nav-item .nav-link {
      font-weight: 400;
      padding: 0.6375rem 1.5625rem; }

  .main-sidebar .nav .nav-item.light .nav-link {
    color: rgba(85,85,85,1);
    border-bottom: 1px solid rgba(255,255,255,0.75);
}

    .main-sidebar .nav .nav-item.dark .nav-link {
      color: white;
      border-bottom: 1px solid rgba(85,85,85,0.25);
   }

    .main-sidebar .nav .nav-item .nav-link i {
      min-width: 1.25rem;
      font-size: 90%;
      text-align: center;
      vertical-align: middle;
      will-change: color;
      color: rgba(85,85,85,1);
      transition: color 200ms ease;
      margin-right: 0.375rem; }

      .main-sidebar .nav .nav-item .nav-link i.light {
        color: rgba(85,85,85,1);}
    .main-sidebar .nav .nav-item .nav-link i.material-icons {
      font-size: 1.125rem;
      top: -1px; }
  .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item:hover, .main-sidebar .nav .nav-item .nav-link:hover {
    box-shadow: inset 0.1875rem 0 0 rgba(0,0,0,0);
    color: #4D9D47; }
    .main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item:hover i, .main-sidebar .nav .nav-item .nav-link:hover i {
      color: #4D9D47; }
  .main-sidebar .dropdown-menu {
    position: static !important;
    transform: translate(0) !important;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    border: none;
    padding: 0;
    box-shadow: inset 0 -0.1875rem 0.1875rem rgba(61, 81, 112, 0.08); }

    .main-sidebar .dropdown-menu .dropdown-item.light {
        /*border-bottom: 1px solid rgba(225,225,225,1);*/
        color: rgba(85,85,85,1);
    }

    .main-sidebar .dropdown-menu .dropdown-item.dark {
        /*border-bottom: 1px solid rgba(85,85,85,1);*/
        color: white;
    }

    .main-sidebar .dropdown-menu .dropdown-item {
      padding: 0.75rem 2.3rem;
      font-size: 0.7125rem;
      font-weight: 500; }
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
        .main-sidebar .dropdown-menu .dropdown-item {
          font-weight: 300; } }
      .main-sidebar .dropdown-menu .dropdown-item:hover, .main-sidebar .dropdown-menu .dropdown-item.active {
        background-color: rgba(0,0,0,0);
    }
      .main-sidebar .dropdown-menu .dropdown-item:last-of-type {
        border-bottom: 1px solid rgba(245,245,245,0.1); }
    .main-sidebar .dropdown-menu .dropdown-divider {
      margin: 0; }
  .main-sidebar .dropdown-toggle {
    position: relative; }
    .main-sidebar .dropdown-toggle::after {
      border: none;
      width: 1.25rem;
      height: 1.25rem;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
      background-position: center center;
      width: 0.875rem;
      height: 0.5625rem;
      position: absolute;
      top: 50%;
      right: 0.625rem;
      transform: translateY(-50%);
      opacity: .1;
      transition: transform 250ms ease-in-out;
      will-change: transform; }
  .main-sidebar .dropdown.show .dropdown-toggle::after {
    transform: translateY(-50%) rotateZ(180deg); }
  @media (max-width: 767.98px) {
    .main-sidebar__search {
      box-sizing: border-box;
      border-right: 0 !important;
      padding: 0.625rem 0;
      border-bottom: 1px solid #e1e5eb; }
      .main-sidebar__search .input-group {
        margin: 0 !important; } }

.icon-sidebar-nav .main-sidebar {
  transition: transform 200ms ease-in-out, width 200ms ease-in-out;
  width: 4.75rem; }
  @media (max-width: 767.98px) {
    .icon-sidebar-nav .main-sidebar {
      width: 100%; }
      .icon-sidebar-nav .main-sidebar span {
        opacity: 1 !important;
        font-size: inherit !important; } }
  .icon-sidebar-nav .main-sidebar:hover {
    width: 13.75rem; }
    @media (max-width: 767.98px) {
      .icon-sidebar-nav .main-sidebar:hover {
        width: 100%; } }
    .icon-sidebar-nav .main-sidebar:hover .nav-link {
      text-overflow: visible; }
      .icon-sidebar-nav .main-sidebar:hover .nav-link span {
        opacity: 1 !important;
        font-size: inherit !important; }
  .icon-sidebar-nav .main-sidebar .nav-wrapper {
    overflow: visible; }
  .icon-sidebar-nav .main-sidebar .nav .nav-link {
    text-overflow: initial; }
    .icon-sidebar-nav .main-sidebar .nav .nav-link span {
      opacity: 0;
      font-size: 0;
      transition: opacity 200ms ease-in-out; }
  .icon-sidebar-nav .main-sidebar .nav-item {
    overflow: visible; }
  .icon-sidebar-nav .main-sidebar .dropdown-toggle::after {
    background-size: 100%; }
  @media (min-width: 768px) {
    .icon-sidebar-nav .main-sidebar .dropdown-menu {
      border-radius: 0 0.375rem 0.375rem 0;
      position: absolute !important;
      left: 100% !important;
      width: 12.5rem;
      box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
      transition: opacity 250ms ease-in-out;
      display: block;
      visibility: hidden;
      opacity: 0; }
      .icon-sidebar-nav .main-sidebar .dropdown-menu.show {
        opacity: 1;
        visibility: visible; }
      .icon-sidebar-nav .main-sidebar .dropdown-menu .dropdown-item:first-of-type {
        border-radius: 0 0.375rem 0 0; }
      .icon-sidebar-nav .main-sidebar .dropdown-menu .dropdown-item:last-of-type {
        border-bottom: 0;
        border-radius: 0 0 0.375rem 0; } }

.icon-sidebar-nav .main-content {
  padding: 0 0 0 4.75rem; }
  @media (max-width: 767.98px) {
    .icon-sidebar-nav .main-content {
      padding-left: 0; } }

.main-navbar__search .input-group-prepend .input-group-text,
.main-sidebar__search .input-group-prepend .input-group-text {
  font-size: 0.6875rem;
  padding: 0.75rem 1.0625rem; }

.main-navbar__search input.form-control,
.main-sidebar__search input.form-control {
  border: none;
  font-size: 0.7125rem;
  border-radius: 0; }
  .main-navbar__search input.form-control:hover, .main-navbar__search input.form-control:focus,
  .main-sidebar__search input.form-control:hover,
  .main-sidebar__search input.form-control:focus {
    box-shadow: none; }

.main-content > .main-content-container.container-fluid {
  min-height: calc(100vh - 7.5rem); }

.main-footer {
  height: 3.75rem; }
  .main-footer .copyright {
    color: #818EA3; }
  @media (max-width: 767.98px) {
    .main-footer {
      display: block !important;
      height: auto; }
      .main-footer .nav {
        width: 100%;
        display: block;
        border-bottom: 1px solid #e1e5eb;
        padding-bottom: 0.75rem; }
      .main-footer .copyright {
        display: inline-block;
        width: 100%;
        padding: 1rem;
        text-align: center; } }

.page-header .page-title {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
	color:white;
  margin: 0;
  padding: 0; }
  @media (max-width: 767.98px) {
    .page-header .page-title {
      font-size: 2rem; } }

.page-header .page-subtitle {
  letter-spacing: 0.125rem;
  color: rgba(225,225,225,1);
  font-size: 0.625rem; }
  @media (max-width: 767.98px) {
    .page-header .page-subtitle {
      font-size: 0.7125rem;
      font-weight: 600; } }

.stats-small {
  min-height: 8.7rem;
  overflow: hidden !important; }
  .stats-small canvas {
    position: absolute;
    bottom: 0; }
  .stats-small__data {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-flow: column;
    max-width: 50%;
    z-index: 1;
}
  .stats-small__label {
    font-size: 0.625rem;
    letter-spacing: 0.0625rem;
    color: #818EA3; }
  .stats-small__value {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: 500; }
  .stats-small__percentage {
    position: relative;
    display: table;
    margin-left: auto;
    padding-left: 0.9375rem; }
    .stats-small__percentage--increase, .stats-small__percentage--decrease {
      font-size: 0.75rem; }
      .stats-small__percentage--increase::before, .stats-small__percentage--decrease::before {
        content: "";
        width: 0.75rem;
        height: 0.375rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-position: center center;
        background-repeat: no-repeat; }
    .stats-small__percentage--increase {
      color: #17c671; }
      .stats-small__percentage--increase::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMTdjNjcxIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz4gPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiA8L3N2Zz4=); }
    .stats-small__percentage--decrease {
      color: #c4183c; }
      .stats-small__percentage--decrease::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjYzQxODNjIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==); }

.card-group .stats-small {
  position: relative;
  overflow: hidden; }

.country-stats .table td {
  padding: 0.5rem 1rem;
  font-size: 0.75rem; }

.country-stats .table tbody tr td {
  font-weight: 400; }
  .country-stats .table tbody tr td:nth-child(1), .country-stats .table tbody tr td:nth-child(2) {
    color: #3D5170; }
  .country-stats .table tbody tr td:nth-child(3) {
    color: #818EA3; }

.country-stats .country-flag {
  max-width: 1.25rem; }

.ubd-stats__legend, .ubd-stats__item {
  display: flex; }

.ubd-stats__legend {
  width: 100%; }

.ubd-stats__item {
  flex-direction: column;
  flex: 1;
  text-align: center; }
  .ubd-stats__item i {
    font-size: 1.375rem;
    margin-bottom: 0.3125rem; }

.ubd-stats__category {
  color: #818EA3;
  font-size: 0.7125rem;
  margin-bottom: 0.125rem; }

.ubd-stats__value {
  font-size: 1.0625rem;
  color: #3D5170;
  font-weight: 500;
  line-height: 1rem; }

.go-stats .list-group .list-group-item {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.go-stats__label {
  margin: 0;
  padding: 0;
  font-size: 0.95rem;
  font-weight: 500; }

.go-stats__meta {
  font-size: 0.75rem;
  color: #818EA3; }
  .go-stats__meta strong {
    color: #3D5170; }

.go-stats__value {
  flex: 1;
  padding-right: 0.625rem; }

#analytics-overview-date-range {
  max-width: 350px; }
  @media (max-width: 575.98px) {
    #analytics-overview-date-range {
      max-width: 100%; } }

.sc-stats {
  font-size: 0.875rem;
  font-weight: 400; }
  @media screen and (min-width: 992px) and (max-width: 1170px) {
    .sc-stats {
      font-size: 0.75rem; } }
  .sc-stats__image {
    max-width: 80px; }
    @media (max-width: 991.98px) {
      .sc-stats__image {
        max-width: 3.125rem; } }
    .sc-stats__image img {
      width: 2.8125rem;
      height: auto; }
  .sc-stats__title {
    padding-left: 0.25rem !important; }
    @media screen and (max-width: 1170px) {
      .sc-stats__title {
        padding-left: 0.625rem !important; } }
  .sc-stats__percentage {
    color: #818EA3; }
  .sc-stats .card-body {
    position: relative;
    overflow-y: auto;
    min-height: 12.5rem; }
    @media screen and (max-width: 992px) {
      .sc-stats .card-body {
        min-height: 21rem; } }
  .sc-stats .container-fluid {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1px; }
    .sc-stats .container-fluid .row {
      display: flex;
      flex: 1;
      border-bottom: 1px solid #e1e5eb;
      min-height: 3.75rem; }
      .sc-stats .container-fluid .row:last-of-type {
        border-bottom: 0; }
      .sc-stats .container-fluid .row > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0; }

.lo-stats {
  font-size: 0.7125rem; }
  .lo-stats .container-fluid {
    overflow-x: auto; }
  .lo-stats table {
    min-width: 600px; }
  .lo-stats thead th {
    font-size: 0.75rem;
    font-weight: 400;
    border-width: 1px;
    border-top: none;
    padding: 0.5rem 0.75rem; }
  .lo-stats td {
    vertical-align: middle; }
  .lo-stats__image {
    width: 70px; }
    .lo-stats__image img {
      width: 3.125rem;
      height: 3.125rem; }
  .lo-stats__items, .lo-stats__total {
    font-weight: 400; }
  .lo-stats__order-details span {
    display: block; }
  .lo-stats__order-details span:first-child {
    font-weight: 500;
    font-size: 1rem; }
  .lo-stats__order-details span:last-child {
    font-size: 0.625rem;
    color: #818EA3; }
  .lo-stats .card-body {
    position: relative;
    overflow-x: auto;
    min-height: 21rem; }
  .lo-stats .container-fluid {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 41rem; }
    .lo-stats .container-fluid .row:not(.lo-stats__header) {
      display: flex;
      flex: 1;
      border-bottom: 1px solid #e1e5eb;
      min-height: 3.75rem; }
      .lo-stats .container-fluid .row:not(.lo-stats__header):last-of-type {
        border-bottom: 0; }
      .lo-stats .container-fluid .row:not(.lo-stats__header) > div {
        display: flex;
        justify-content: center;
        flex-direction: column; }

#sales-overview-date-range {
  max-width: 350px; }
  @media (max-width: 575.98px) {
    #sales-overview-date-range {
      max-width: 100%; } }

.file-manager__group-title {
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 1rem 0; }

.file-manager-list td, .file-manager-list th {
  border-left: none !important;
  border-right: none !important; }

.file-manager-list .file-manager__item-title, .file-manager-list .file-manager__item-meta {
  margin: 0; }

.file-manager-list .file-manager__item-title {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem; }

.file-manager-list .file-manager__item-meta {
  display: inline-block;
  font-size: 0.6875rem;
  color: #818EA3;
  line-height: 0.7125rem; }

.file-manager-list .file-manager__item-icon {
  padding-right: 0 !important; }
  .file-manager-list .file-manager__item-icon div {
    border-radius: 50%;
    font-size: 0.9375rem;
    text-align: center;
    color: #818EA3;
    width: 2.1875rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    background: #f5f6f8;
    box-shadow: 0 0 0 2px #fff, inset 0 0 3px rgba(0, 0, 0, 0.2); }
  .file-manager-list .file-manager__item-icon i {
    top: 2px; }

.file-manager-cards__search {
  max-width: 200px; }
  @media (max-width: 991.98px) {
    .file-manager-cards__search {
      max-width: 100%; } }

.file-manager-cards .file-manager__item {
  overflow: hidden;
  position: relative; }
  .file-manager-cards .file-manager__item .card-footer {
    display: flex; }
  .file-manager-cards .file-manager__item:hover {
    cursor: pointer; }
  .file-manager-cards .file-manager__item:before, .file-manager-cards .file-manager__item:after {
    content: '';
    position: absolute; }
  .file-manager-cards .file-manager__item:before {
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    background-color: #007bff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNOSAxNi4xN0w0LjgzIDEybC0xLjQyIDEuNDFMOSAxOSAyMSA3bC0xLjQxLTEuNDF6Ii8+PC9zdmc+);
    background-size: 1.0625rem;
    width: 1.5625rem;
    height: 1.5625rem;
    right: 0.625rem;
    top: -1.875rem;
    transform: rotate(-90deg);
    will-change: top, right, opacity, transform;
    transition: top 150ms cubic-bezier(0.27, 0.01, 0.38, 1.06), right 150ms cubic-bezier(0.27, 0.01, 0.38, 1.06), opacity 150ms cubic-bezier(0.27, 0.01, 0.38, 1.06), transform 150ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
  .file-manager-cards .file-manager__item:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    border-radius: 0.625rem;
    box-shadow: inset 0 0 0 0px rgba(0, 123, 255, 0.2);
    will-change: box-shadow;
    transition: box-shadow 250ms ease-in-out; }
  .file-manager-cards .file-manager__item--selected:before {
    top: 0.625rem;
    transform: rotate(0); }
  .file-manager-cards .file-manager__item--selected:after {
    box-shadow: inset 0 0 3.125rem rgba(0, 123, 255, 0.2), inset 0 0 0.625rem rgba(0, 123, 255, 0.4), inset 0 0 0 0.125rem rgba(0, 123, 255, 0.75); }
  .file-manager-cards .file-manager__item--directory:before {
    width: 1.25rem;
    height: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    right: -1.25rem; }
  .file-manager-cards .file-manager__item--directory.file-manager__item--selected:before {
    right: 10px;
    background-size: 70%; }

.file-manager-cards .file-manager__item-icon {
  position: relative;
  margin-right: 0.625rem;
  font-size: 1.0625rem;
  top: 2px; }
  .file-manager-cards .file-manager__item-icon i.material-icons {
    top: 0; }

.file-manager-cards .file-manager__item-title {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 0.875rem;
  padding-right: 1.25rem; }

.file-manager-cards .file-manager__item-size {
  margin: auto 0;
  font-size: 0.6875rem; }

.file-manager-cards .file-manager__item-preview {
  background: #F8F8F8; }
  .file-manager-cards .file-manager__item-preview img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: table;
    box-shadow: 0 0.125rem 0.5625rem rgba(0, 0, 0, 0.13); }

.user-details .card-header {
  overflow: hidden; }

.user-details .card-body {
  margin-top: -3.125rem;
  z-index: 1; }
  .user-details .card-body h4 {
    font-weight: 500; }

.user-details__bg {
  width: 100%;
  position: relative;
  max-height: 7.1875rem; }
  .user-details__bg img {
    width: 100%; }
  .user-details__bg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(90, 97, 105, 0.3); }

.user-details__avatar {
  border-radius: 50%;
  overflow: hidden;
  max-width: 6.25rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); }
  .user-details__avatar img {
    width: 100%; }

.user-details__social {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .user-details__social li {
    display: inline-block; }
  .user-details__social a {
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    background: #E9ECEF;
    color: #818EA3; }

.user-details__user-data .col span {
  display: block; }
  .user-details__user-data .col span:first-child {
    font-weight: 400;
    color: #3D5170; }
  .user-details__user-data .col span:last-child {
    color: #818EA3; }

.user-details__tags .badge {
  font-weight: 300; }

.user-stats h4 {
  font-weight: 600; }

.user-stats span {
  font-size: 0.6875rem; }

.user-teams__image {
  max-width: 3.125rem; }
  .user-teams__image img {
    width: 100%;
    max-width: 3.125rem; }

.user-teams__info span:first-child {
  font-weight: 400; }

.user-teams__info span:last-child {
  font-size: 80%; }

.user-teams .card-body {
  position: relative;
  overflow-y: auto;
  min-height: 14.0625rem; }
  @media screen and (max-width: 992px) {
    .user-teams .card-body {
      min-height: 21rem; } }

.user-teams .container-fluid {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .user-teams .container-fluid .row {
    display: flex;
    flex: 1;
    border-bottom: 1px solid #e1e5eb;
    min-height: 4.6875rem; }
    .user-teams .container-fluid .row:last-of-type {
      border-bottom: 0; }
    .user-teams .container-fluid .row > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0; }

.user-activity__item {
  display: flex;
  margin-left: 1.875rem;
  border-left: 1px solid #E9ECEF;
  border-bottom: 1px solid #E9ECEF;
  font-weight: 400; }
  .user-activity__item:last-child {
    border-bottom: 0; }
  .user-activity__item::after {
    display: block;
    clear: both;
    content: ""; }
  .user-activity__item__icon {
    text-align: center;
    border-radius: 50%;
    float: left;
    width: 1.875rem;
    height: 1.875rem;
    min-width: 1.875rem;
    background: #f5f6f8;
    margin-left: -0.9375rem;
    margin-right: 0.9375rem;
    box-shadow: 0 0 0 2px white, inset 0 0 3px rgba(0, 0, 0, 0.2); }
    .user-activity__item__icon i {
      font-size: 1rem;
      line-height: 1.875rem;
      color: #aeb9c4; }
  .user-activity__item__content {
    float: left; }
    .user-activity__item__content p {
      margin: 0; }
    .user-activity__item__content a {
      font-weight: 400; }
    .user-activity__item__content span {
      font-size: 80%; }
  .user-activity__item__action {
    float: right; }
  .user-activity__item__task-list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .user-activity__item__task-list .custom-control {
      line-height: 1.5rem; }

.edit-user-details .card-header {
  overflow: hidden; }

.edit-user-details .card-body {
  z-index: 1; }

.edit-user-details__bg {
  width: 100%;
  position: relative;
  max-height: 7.1875rem; }
  .edit-user-details__bg img {
    width: 100%; }
  .edit-user-details__bg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(90, 97, 105, 0.5);
    will-change: background-color;
    transition: background-color 250ms ease-in-out; }
  .edit-user-details__bg .edit-user-details__change-background {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    opacity: 1;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px dashed rgba(233, 236, 239, 0.3);
    padding: 0.5275rem 0.9375rem;
    color: #fff;
    border-radius: 0.375rem;
    box-shadow: 0.375rem;
    font-size: 0.75rem;
    will-change: opacity;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out; }
    .edit-user-details__bg .edit-user-details__change-background:hover {
      cursor: pointer;
      transform: translate(-50%, -50%) scale(1.05); }
    .edit-user-details__bg .edit-user-details__change-background i {
      font-size: 0.9375rem;
      top: 0.125rem; }

.edit-user-details__avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  max-width: 7.5rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); }
  .edit-user-details__avatar img {
    width: 100%; }
  .edit-user-details__avatar__change {
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    font-size: 1.875rem;
    background: rgba(255, 255, 255, 0.95);
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
    .edit-user-details__avatar__change i {
      color: #9ea8b9;
      line-height: 7.5rem; }
  .edit-user-details__avatar:hover .edit-user-details__avatar__change {
    opacity: 1;
    cursor: pointer; }

.auth-form {
  min-width: 19.375rem;
  max-width: 350px; }
  .auth-form .card-body {
    overflow: hidden;
    box-shadow: inset 0 4px 0 0 #007bff;
    border-radius: 0.625rem; }
  .auth-form__logo {
    max-width: 2.5rem; }
  .auth-form__title {
    font-size: 1.125rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
  .auth-form__social-icons {
    margin: 0;
    padding: 0; }
    .auth-form__social-icons li {
      list-style: none;
      display: inline-block; }
      .auth-form__social-icons li a {
        padding: 0 0.75rem;
        font-size: 1.25rem;
        color: #CACEDB;
        transition: color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06); }
        .auth-form__social-icons li a:hover {
          color: #007bff; }
  .auth-form__meta a {
    font-weight: 400;
    color: #818EA3; }
    .auth-form__meta a:hover {
      color: #3D5170; }
