/* DayGrid */
.fc-header-toolbar {
    display: none !important;
}
.fc-theme-standard {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-theme-standard table {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-theme-standard thead {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-theme-standard tbody {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-theme-standard th {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-theme-standard tr {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-theme-standard td {
    /*border-color: rgba(0,0,0,0) !important*/
    border-color: #e9e9e !important
}
.fc-event:hover {
    background-color: rgba(0,0,0,0) !important
}
.fc-more {
    font-size: 10px !important;
    font-weight: 600 !important;
}
.fc-more-cell {
    text-align: center !important;
}
.fc-day-today {
    background-color: rgba(0,0,0,0) !important
}
.fc-col-header-cell-cushion {
    color: rgba(85,85,85,1);
    transition: opacity 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.fc-col-header-cell-cushion:hover {
    opacity: 0.6;
    cursor: pointer;
    color: rgba(85,85,85,1);
    text-decoration: none !important
}
.fc-highlight {
    background-color: rgba(175,175,175,0.1) !important
}

/* Timegrid */
.fc-timegrid-event {
    border: none !important;
    background-color: rgba(0,0,0,0) !important
}
.fc-timegrid-slot-label-cushion {
    font-weight: 600
}

@media (prefers-color-scheme: dark) {
    .fc-theme-standard {
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-theme-standard table {
        /*border-color: rgba(0,0,0,0) !important*/
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-theme-standard thead {
        /*border-color: rgba(0,0,0,0) !important*/
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-theme-standard tbody {
        /*border-color: rgba(0,0,0,0) !important*/
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-theme-standard th {
        /*border-color: rgba(0,0,0,0) !important*/
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-theme-standard tr {
        /*border-color: rgba(0,0,0,0) !important*/
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-theme-standard td {
        /*border-color: rgba(0,0,0,0) !important*/
        border-color: rgba(150,150,150,0.15) !important
    }
    .fc-col-header-cell-cushion {
        color: white
    }
    .fc-col-header-cell-cushion:hover {
        color: white
    }
    .fc-highlight {
        background-color: rgba(175,175,175,0.1) !important
    }
}
